package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

class AgentEventApi(private val httpClient: HttpClient) : BaseApi("device_event/") {

    suspend fun executeAllTasks(agentId: String): Boolean {
        return httpClient
            .get(endpoint("task_exec_all/$agentId"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun executeTasksByTaskId(taskIdList: List<Long>): Boolean {
        return httpClient
            .post(endpoint("task_exec_by_selected_ids")) {
                contentType(ContentType.Application.Json)
                setBody(taskIdList)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun cancelAndCleanAgentQueue(agentId: String): Boolean {
        return httpClient
            .get(endpoint("cancel_current_task/$agentId"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramStrategy
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun InstagramStrategySelector(onStrategySelected: (InstagramStrategy) -> Unit = {}) {
    val selectedStrategyType = remember { mutableStateOf(InstagramStrategy.VISIT) }
    LaunchedEffect(Unit) {
        onStrategySelected(selectedStrategyType.value)
    }
    Column {
        SpanText(
            "Strategy Type",
            modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold)
        )
        Gap(10.px)
        DropdownOption(
            selectedStrategyType.value.name,
            InstagramStrategy.values().toList(),
            index = { it.name },
            title = { it.text },
            subtitle = { it.name },
            onOptionSelected = {
                selectedStrategyType.value = it
                onStrategySelected(selectedStrategyType.value)
            }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.DashboardAgent
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TaskStatistic
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DashboardTaskStatistics
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardHomeBody() {
    LaunchedEffect(Unit) {
        DashboardTaskStatistics.instance.refresh(mapOf())
        DeviceService.instance.refresh(mapOf())
        while (this.isActive) {
            delay(10_000)
            kotlin.runCatching {
                DeviceService.instance.refresh(mapOf())
                DashboardTaskStatistics.instance.refresh(mapOf())
            }
        }
    }
    Row(
        horizontalArrangement = Arrangement.SpaceEvenly,
        modifier = Modifier.fillMaxWidth()
    ) {
        TaskStatistic(
            "Completed",
            Colors.Green,
            DashboardTaskStatistics.instance.totalTasks.value,
            DashboardTaskStatistics.instance.completedTasks.value
        )
        TaskStatistic(
            "Tasks In Queue",
            Colors.Orange,
            DashboardTaskStatistics.instance.totalTasks.value,
            DashboardTaskStatistics.instance.queuedTasks.value
        )
        TaskStatistic(
            "Failed Tasks",
            Colors.Red,
            DashboardTaskStatistics.instance.totalTasks.value,
            DashboardTaskStatistics.instance.failedTasks.value
        )
    }
    Gap(20.px)
    if (DeviceService.instance.devices.value.isEmpty()) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(top = 50.px),
            horizontalArrangement = Arrangement.Center
        ) {
            SpanText("No Agents", modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold))
        }
    }
    DeviceService.instance.devices.value.forEach { agent ->
        DashboardAgent(agent)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Th

@Composable
fun TableHeader(
    content: @Composable () -> Unit
): @Composable () -> Unit {
    return {
        Th(
            attrs = {
                style {
                    padding(10.px, 5.px, 10.px, 5.px)
                }
            }
        ) {
            content()
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateListOf
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class InstagramUsernameInputService : SingleResponsibilityService {
    val usernames = mutableStateListOf<String>()

    override suspend fun refresh(params: Map<String, String?>) {
        usernames.clear()
    }

    fun onUsernameInput(text: String): Boolean {
        if (text.endsWith("\n")) {
            text.split("[\\s,]+".toRegex()).filter { it.isNotEmpty() }
                .filter { !usernames.contains(it) }.forEach {
                    usernames.add(it)
                }
            return true
        }
        return false
    }

    companion object {
        val instance = InstagramUsernameInputService()
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.utils

import com.jamshedalamqaderi.socialdroid.webapp.data.models.IntPair
import kotlin.random.Random

object IntPairExt {

    fun IntPair.random(): Int {
        if (first != null && second != null && first == second) return first
        return Random.nextInt(this.first ?: 0, this.second ?: 0)
    }

    fun IntPair.randomPair(): IntPair {
        return IntPair(first, second, random())
    }
}

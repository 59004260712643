package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DeviceUserModel(
    val user: String? = null,
    val deviceId: String? = null,
    val deviceName: String? = null,
    val brandName: String,
    val modelName: String,
    val androidVersion: String,
    val sdkVersion: String,
    val agentVersion: String? = null,
    val agentBuildNumber: Int? = null,
    val isApproved: Boolean,
    val createdAt: LocalDateTime? = null,
    val updatedAt: LocalDateTime? = null,
    val isOnline: Boolean = false,
    val timeZone: String,
    val lastSeenAt: LocalDateTime? = null,
    val currentWorkingTask: Long? = null
)

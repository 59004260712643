package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.models.WorkdaysModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.TaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch

class WorkdaysSubmitService : TaskSubmitService {

    val monday = mutableStateOf(false)
    val tuesday = mutableStateOf(false)
    val wednesday = mutableStateOf(false)
    val thursday = mutableStateOf(false)
    val friday = mutableStateOf(false)
    val saturday = mutableStateOf(false)
    val sunday = mutableStateOf(false)

    var agentId: String = ""

    private val allDays
        get() = listOf(saturday, sunday, monday, tuesday, wednesday, thursday, friday)

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not found")
            return
        }
        catch {
            RestClientFactory.agentUserApi.createOrUpdateWorkdaysSetting(
                agentId,
                WorkdaysModel(
                    saturday.value,
                    sunday.value,
                    monday.value,
                    tuesday.value,
                    wednesday.value,
                    thursday.value,
                    friday.value
                )
            )
            StaticToastMessageService.showSuccessToast("Successfully updated")
        }
    }

    fun loadFromWorkdaysModel(workdaysModel: WorkdaysModel?) {
        if (workdaysModel == null) return
        saturday.value = workdaysModel.saturdayEnabled
        sunday.value = workdaysModel.sundayEnabled
        monday.value = workdaysModel.mondayEnabled
        tuesday.value = workdaysModel.tuesdayEnabled
        wednesday.value = workdaysModel.wednesdayEnabled
        thursday.value = workdaysModel.thursdayEnabled
        friday.value = workdaysModel.fridayEnabled
    }

    fun toggleSelectAll() {
        val shouldSelectAll = !isSelectedAll()
        saturday.value = shouldSelectAll
        sunday.value = shouldSelectAll
        monday.value = shouldSelectAll
        tuesday.value = shouldSelectAll
        wednesday.value = shouldSelectAll
        thursday.value = shouldSelectAll
        friday.value = shouldSelectAll
    }

    fun isSelectedAll(): Boolean {
        return allDays
            .map { it.value }
            .reduce { acc, value -> acc && value }
    }
}

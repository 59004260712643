package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.Table
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableData
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.IgAppState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.ToolType
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.Tool
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramSettingCopyService
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaGear
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun ToolTable(
    context: PageContext,
    tools: MutableState<List<Tool>>,
    agentId: String,
    selectedInstagramTool: MutableState<InstagramTool>,
    showSettingDialog: MutableState<Boolean>,
    showCopyToDialog: MutableState<Boolean>,
    isActionTool: Boolean
) {
    Box(modifier = Modifier.fillMaxWidth().padding(5.px)) {
        Table(
            dataList = tools.value,
            emptyText = "NO TOOLS",
            headers = buildList {
                addAll(
                    listOf(
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Tool name")
                        },
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Create task")
                        },
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Queued tasks")
                        },
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Failed tasks")
                        },
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Completed tasks")
                        },
                        com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                            SpanText("Total tasks")
                        }
                    )
                )
                if (isActionTool) {
                    addAll(
                        listOf(
                            com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                                SpanText("Status")
                            },
                            com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader {
                                SpanText("Actions")
                            }
                        )
                    )
                }
            },
            cells = buildList {
                addAll(
                    listOf(
                        TableData(modifier = Modifier.width(250.px)) { _, model ->
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .backgroundColor(Colors.Blue.copy(alpha = 30))
                                    .padding(10.px)
                                    .borderRadius(10.px)
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        context.router.navigateTo("/instagram-tasks/$agentId/${model.toolIndex}")
                                    },
                                horizontalArrangement = Arrangement.Start
                            ) {
                                SpanText(model.toolName)
                            }
                        },
                        TableData(modifier = Modifier.width(120.px)) { _, model ->
                            FaPlus(
                                modifier = Modifier
                                    .backgroundColor(Colors.Green.copy(alpha = 50))
                                    .padding(5.px)
                                    .borderRadius(50.percent)
                                    .onClick {
                                        context.router.navigateTo("/create-instagram-task/$agentId/${model.toolIndex}")
                                    }
                            )
                        },
                        TableData { _, model ->
                            SpanText(model.queuedTasks.toString())
                        },
                        TableData { _, model ->
                            SpanText(model.failedTasks.toString())
                        },
                        TableData { _, model ->
                            SpanText(model.completedTasks.toString())
                        },
                        TableData { _, model ->
                            SpanText(model.totalTasks.toString())
                        }
                    )
                )
                if (isActionTool) {
                    addAll(
                        listOf(
                            TableData { _, model ->
                                AppStatusChip(
                                    model.status == IgAppState.WORKING,
                                    modifier = Modifier
                                )
                            },
                            TableData { _, model ->
                                val igTool = InstagramTool.valueOf(model.toolIndex)
                                if (igTool.toolType == ToolType.ACTION) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.Center
                                    ) {
                                        TextButton(
                                            "Copy to",
                                            backgroundColor = Colors.Green.copy(alpha = 50),
                                            hoverColor = Colors.Green.copy(alpha = 45),
                                            modifier = Modifier
                                                .padding(leftRight = 6.px, topBottom = 3.px)
                                                .borderRadius(5.px)
                                                .onClick {
                                                    GlobalScope.launch {
                                                        InstagramSettingCopyService
                                                            .instance
                                                            .copySetting(agentId, igTool)
                                                    }
                                                    showCopyToDialog.value = true
                                                }
                                        )
                                        Gap(10.px)
                                        FaGear(
                                            modifier = Modifier
                                                .onClick {
                                                    val instagramTool =
                                                        InstagramTool.valueOrNull(model.toolIndex)
                                                    if (instagramTool?.toolType == ToolType.ACTION) {
                                                        selectedInstagramTool.value = instagramTool
                                                        showSettingDialog.value = true
                                                    }
                                                }
                                        )
                                    }
                                }
                            }
                        )
                    )
                }
            }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramSettingSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramToolsTableService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramToolsTable(context: PageContext, agentId: String) {
    val selectedInstagramTool = remember { mutableStateOf(InstagramTool.IG_FOLLOW) }
    val showSettingDialog = remember { mutableStateOf(false) }
    val showCopyToDialog = remember { mutableStateOf(false) }

    LaunchedEffect(agentId) {
        InstagramToolsTableService.instance.refresh(mapOf("agentId" to agentId))
    }
    TableHeader("Action bots")
    ToolTable(
        context,
        InstagramToolsTableService.instance.actionTools,
        agentId,
        selectedInstagramTool,
        showSettingDialog,
        showCopyToDialog,
        true
    )
    Gap(20.px)
    TableHeader("Scrapers")
    ToolTable(
        context,
        InstagramToolsTableService.instance.scraperTools,
        agentId,
        selectedInstagramTool,
        showSettingDialog,
        showCopyToDialog,
        false
    )
    if (showCopyToDialog.value) {
        InstagramCopyToDialog(showCopyToDialog)
    }
    if (showSettingDialog.value) {
        val instagramSettingSubmitService =
            remember { mutableStateOf<InstagramSettingSubmitService?>(null) }
        LaunchedEffect(selectedInstagramTool.value) {
            instagramSettingSubmitService.value =
                InstagramSettingSubmitService(agentId, selectedInstagramTool.value)
        }
        Dialog(
            onClose = {
                instagramSettingSubmitService.value = null
                showSettingDialog.value = false
            },
            onSubmit = {
                showSettingDialog.value = false
                GlobalScope.launch {
                    catch {
                        instagramSettingSubmitService.value?.onSubmit()
                        InstagramToolsTableService.instance.refresh(mapOf("agentId" to agentId))
                    }
                }
            }
        ) {
            SpanText("Setting", modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold))
            Gap(20.px)
            InstagramToolSetting(
                agentId,
                selectedInstagramTool.value,
                instagramSettingSubmitService.value
            )
        }
    }
}

@Composable
fun TableHeader(title: String) {
    SpanText(
        title,
        modifier = Modifier.fontSize(12.pt).fontWeight(FontWeight.Bold).margin(left = 10.px)
    )
}

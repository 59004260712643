package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.datetime.LocalTime
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TimeInput

@Composable
fun TimeField(
    time: LocalTime,
    onChange: (LocalTime) -> Unit = {}
) {
    TimeInput(
        time.toString(),
        attrs = TextFieldStyle
            .toModifier()
            .outline(0.px, LineStyle.None)
            .width(80.px)
            .height(30.px)
            .borderRadius(5.px)
            .toAttrs {
                onInput {
                    onChange(LocalTime.parse(it.value))
                }
            }
    )
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class InstagramCommonBotDto(
    val deviceId: String,
    val data: List<InstagramToolTaskDto>,
    val scraperDataLimit: Int? = null
)

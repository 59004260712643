package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class InstagramAppService : SingleResponsibilityService {
    val apps = mutableStateListOf<DeviceAppModel>()
    val selectedApps = mutableStateListOf<Long>()

    val isSelectedAll = mutableStateOf(false)

    override suspend fun refresh(params: Map<String, String?>) {
        val agentId = params["agentId"] ?: return
        apps.clear()
        apps.addAll(RestClientFactory.instagramApi.allApps(agentId))
    }

    fun onAppSelection(taskId: Long) {
        if (selectedApps.contains(taskId)) {
            selectedApps.remove(taskId)
            return
        }
        if (apps.find { it.id == taskId } != null) {
            selectedApps.add(taskId)
        }
        updateSelectAllStatus()
    }

    fun onSelectAll() {
        if (isSelectedAll.value) {
            selectedApps.clear()
        } else {
            selectedApps.addAll(
                apps
                    .filter { !selectedApps.contains(it.id) }
                    .map { it.id }
            )
        }
        updateSelectAllStatus()
    }

    fun getSelectedAppsPackageNames(): List<String> {
        return apps.filter { selectedApps.contains(it.id) }.map { it.packageName }
    }

    private fun updateSelectAllStatus() {
        isSelectedAll.value =
            if (apps.isEmpty()) {
                false
            } else {
                apps.map { selectedApps.contains(it.id) }
                    .reduce { acc, b -> acc && b }
            }
    }
}

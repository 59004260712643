package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.DeviceUserStatus
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceUserStatusModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun AgentStatus(agentId: String, modifier: Modifier = Modifier) {
    val status = remember { mutableStateOf(DeviceUserStatusModel(DeviceUserStatus.IDLE, "")) }
    LaunchedEffect(agentId) {
        while (this.isActive) {
            delay(3000)
            kotlin.runCatching {
                val resultStatus = RestClientFactory.agentUserApi.agentStatus(agentId)
                if (resultStatus != null) {
                    status.value = resultStatus
                }
            }.onFailure {
                status.value = DeviceUserStatusModel(DeviceUserStatus.IDLE, "")
            }
        }
    }
    Row(
        modifier = modifier
            .backgroundColor(Colors.White)
            .border(1.px)
            .borderColor(Colors.Black)
            .borderRadius(5.px)
            .width(300.px)
            .height(60.px)
            .padding(5.px)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth().fillMaxHeight(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            SpanText(status.value.status.text, modifier = Modifier.fontWeight(FontWeight.Bold))
            Gap(5.px)
            SpanText(
                status.value.appName,
                modifier = Modifier.fontSize(10.pt).color(Colors.Blue.copy(alpha = 60))
            )
        }
        TextButton(
            "STOP",
            backgroundColor = Colors.Red,
            hoverColor = Colors.Red.copy(alpha = 98),
            modifier = Modifier
                .size(50.px)
                .borderRadius(5.px)
                .fontSize(8.pt)
                .fontWeight(FontWeight.Bold)
                .color(Colors.WhiteSmoke)
                .boxShadow(2.px, 2.px, 5.px, 2.px, Colors.Black.copy(alpha = 50))
                .onClick {
                    GlobalScope.launch {
                        catch {
                            if (RestClientFactory.agentEventApi.cancelAndCleanAgentQueue(agentId)) {
                                StaticToastMessageService.showSuccessToast("Stopped running task")
                            } else {
                                StaticToastMessageService.showErrorToast("Stopping failed")
                            }
                        }
                    }
                }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramAppsSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramStrategySelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramUsernamesInput
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ShowDataButton
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ShowUsernamesDialog
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TaskSubmitButton
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramFollowTaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramUsernameInputService
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramFollowTaskCreateLayout(agentId: String) {
    val showUsernameDialog = remember { mutableStateOf(false) }
    LaunchedEffect(agentId) {
        InstagramFollowTaskSubmitService.instance.agentId = agentId
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        InstagramStrategySelector(onStrategySelected = {
            InstagramFollowTaskSubmitService.instance.strategy = it
        })
        Gap(20.px)
        InstagramAppsSelector(agentId) { selectedPackageNames ->
            InstagramFollowTaskSubmitService.instance.appPackageNames = selectedPackageNames
        }
        Gap(20.px)
        InstagramUsernamesInput()
        Gap(20.px)
        Row(modifier = Modifier.fillMaxWidth()) {
            ShowDataButton(
                "Show Usernames (${InstagramUsernameInputService.instance.usernames.size})",
                onClick = {
                    showUsernameDialog.value = true
                }
            )
            Gap(10.px)
            TaskSubmitButton(onClick = {
                GlobalScope.launch {
                    InstagramFollowTaskSubmitService.instance.onSubmit()
                }
            })
        }
    }
    if (showUsernameDialog.value) {
        ShowUsernamesDialog(
            "Usernames",
            InstagramUsernameInputService.instance.usernames,
            onDelete = { index ->
                InstagramUsernameInputService.instance.usernames.removeAt(index)
            },
            deleteAll = {
                InstagramUsernameInputService.instance.usernames.clear()
            },
            hideDialog = {
                showUsernameDialog.value = false
            }
        )
    }
}

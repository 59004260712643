package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateListOf
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class InstagramLocationInputService : SingleResponsibilityService {
    val locations = mutableStateListOf<String>()

    override suspend fun refresh(params: Map<String, String?>) {
        locations.clear()
    }

    fun onLocationInput(text: String): Boolean {
        if (text.endsWith("\n")) {
            text.split("\n").filter { it.isNotEmpty() }
                .filter { !locations.contains(it) }.forEach {
                    locations.add(it)
                }
            return true
        }
        return false
    }

    companion object {
        val instance = InstagramLocationInputService()
    }
}

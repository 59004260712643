package com.jamshedalamqaderi.socialdroid.webapp.domain.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.IgAppState

data class Tool(
    val toolIndex: String,
    val toolName: String,
    val queuedTasks: Int,
    val failedTasks: Int,
    val completedTasks: Int,
    val totalTasks: Int,
    val status: IgAppState
)

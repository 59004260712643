package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceUserModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class DeviceService : SingleResponsibilityService {
    val devices = mutableStateOf<List<DeviceUserModel>>(emptyList())

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            devices.value = RestClientFactory.agentUserApi.allDevices().sortedBy { it.createdAt }
        }
    }

    companion object {
        val instance = DeviceService()
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateListOf
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService

class InstagramHashTagInputService : SingleResponsibilityService {
    val hashTags = mutableStateListOf<String>()

    override suspend fun refresh(params: Map<String, String?>) {
        hashTags.clear()
    }

    fun onHashTagInput(text: String): Boolean {
        if (text.endsWith("\n")) {
            text.split("[\\s,]+".toRegex()).filter { it.isNotEmpty() }
                .filter { !hashTags.contains(it) }.forEach {
                    if (it.startsWith("#")) {
                        hashTags.add(it)
                    } else {
                        StaticToastMessageService.showErrorToast("HashTag must be start with `#`")
                        return false
                    }
                }
            return true
        }
        return false
    }

    companion object {
        val instance = InstagramHashTagInputService()
    }
}

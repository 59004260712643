package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.onAnimationEnd
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

val SwitchOnAnimation by Keyframes {
    from {
        Modifier.left(5.px)
    }
    to {
        Modifier.left(24.px)
    }
}

val SwitchOffAnimation by Keyframes {
    from {
        Modifier.left(24.px)
    }
    to {
        Modifier.left(5.px)
    }
}

@Composable
fun Switch(enabled: Boolean, onClicked: (Boolean) -> Unit = {}) {
    val enableSwitch = remember { mutableStateOf(enabled) }
    Box(
        modifier = Modifier
            .width(40.px)
            .height(20.px)
            .backgroundColor(if (enabled) Colors.Blue.copy(alpha = 50) else Colors.Red.copy(alpha = 80))
            .border(
                width = 1.px,
                style = LineStyle.Solid,
                color = Color.white
            )
            .borderRadius(10.px)
            .position(Position.Relative)
            .onClick {
                enableSwitch.value = !enableSwitch.value
            },
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .size(10.px)
                .backgroundColor(Color.white)
                .borderRadius(50.percent)
                .position(Position.Absolute)
                .left(if (enableSwitch.value) 24.px else 3.px)
                .animation(
                    if (enableSwitch.value) {
                        SwitchOnAnimation.toAnimation(0.5.s)
                    } else {
                        SwitchOffAnimation.toAnimation(
                            0.5.s
                        )
                    }
                )
                .onAnimationEnd {
                    onClicked(enableSwitch.value)
                }
        )
    }
}

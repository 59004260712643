package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceAppModel

@Composable
fun InstagramAppDropdown(
    selectedApp: String,
    apps: List<DeviceAppModel>,
    onAppSelected: (DeviceAppModel) -> Unit = {}
) {
    DropdownOption(
        selectedApp,
        options = apps,
        index = {
            it.packageName
        },
        title = {
            it.appName
        },
        subtitle = {
            it.packageName
        },
        onOptionSelected = {
            onAppSelected(it)
        }
    )
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.Table
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableData
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.IgAppState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramOwnFollowersTaskSubmitService
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import kotlin.math.round

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun AppsTable(
    context: PageContext,
    deviceId: String,
    apps: List<DashboardAppModel> = emptyList(),
    shouldRefresh: () -> Unit = {},
    shouldRefreshTaskCount: () -> Unit = {}
) {
    Table(
        apps,
        headers = listOf(
            TableHeader {
                SpanText("App name")
            },
            TableHeader {
                SpanText("Followers")
            },
            TableHeader {
                SpanText("Fbr")
            },
            TableHeader {
                SpanText("Actions")
            },
            TableHeader {
                SpanText("Completed tasks")
            },
            TableHeader {
                SpanText("Tasks in queue")
            },
            TableHeader {
                SpanText("Failed tasks")
            },
            TableHeader {
                SpanText("Status")
            }

        ),
        cells = listOf(
            TableData { _, app ->
                Row {
                    Image(
                        "/instagram-icon.png",
                        modifier = Modifier
                            .size(50.px)
                            .margin(10.px)
                    )
                    Column(
                        modifier = Modifier.margin(topBottom = 10.px),
                        verticalArrangement = Arrangement.SpaceBetween
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            SpanText(
                                app.appName,
                                modifier = Modifier
                                    .fontSize(12.pt)
                                    .fontWeight(FontWeight.Bold)
                                    .onClick {
                                        context.router.navigateTo("/instagram-tasks/$deviceId/${InstagramTool.IG_FOLLOW}?app=${app.packageName}")
                                    }
                            )
                            Gap(10.px)
                            AppNameEdit(app, shouldRefresh)
                        }
                        Gap(5.px)
                        SpanText(
                            app.packageName,
                            modifier = Modifier
                                .fontSize(10.pt)
                                .onClick {
                                    context.router.navigateTo("/instagram-tasks/$deviceId/${InstagramTool.IG_FOLLOW}?app=${app.packageName}")
                                }
                        )
                    }
                }
            },
            TableData { _, app ->
                SpanText(app.followers.toString())
            },
            TableData { _, app ->
                SpanText((round(app.followBackRatio * 100.0) / 100.0).toString() + "%")
            },
            TableData { _, app ->
                AppActions(deviceId, app, shouldRefresh, shouldRefreshTaskCount)
            },
            TableData { _, app ->
                SpanText(app.completedTasks.toString())
            },
            TableData { _, app ->
                SpanText(app.queuedTasks.toString())
            },
            TableData { _, app ->
                SpanText(app.failedTasks.toString())
            },
            TableData { _, app ->
                AppStatusChip(
                    app.isWorking,
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .onClick {
                            GlobalScope.launch {
                                val updated = RestClientFactory.instagramApi.updateAppState(
                                    app.appId,
                                    if (app.isWorking) IgAppState.SLEEPING else IgAppState.WORKING
                                )
                                if (updated) {
                                    shouldRefresh()
                                }
                            }
                        }
                )
            }
        ),
        emptyText = "NO APPS"
    )
}

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun AppActions(
    agentId: String,
    app: DashboardAppModel,
    shouldRefresh: () -> Unit,
    shouldRefreshTaskCount: () -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth().columnGap(3.px).rowGap(3.px).flexWrap(FlexWrap.Wrap),
        horizontalArrangement = Arrangement.Center
    ) {
        val buttonModifier = Modifier.fontSize(8.pt)
            .padding(3.px)
            .borderRadius(5.px)
            .color(Colors.WhiteSmoke)
        TextButton(
            "Calc Fbr",
            backgroundColor = Colors.LimeGreen,
            hoverColor = Colors.LimeGreen.copy(alpha = 95),
            modifier = buttonModifier.onClick {
                GlobalScope.launch {
                    InstagramOwnFollowersTaskSubmitService.instance.apply {
                        this.agentId = agentId
                        this.appPackageNames = listOf(app.packageName)
                    }
                    InstagramOwnFollowersTaskSubmitService.instance.onSubmit()
                    shouldRefresh()
                }
            }
        )
        TextButton(
            "Clear Tasks",
            backgroundColor = Colors.Red,
            hoverColor = Colors.Red.copy(alpha = 95),
            modifier = buttonModifier.onClick {
                GlobalScope.launch {
                    kotlin.runCatching {
                        val cleared = RestClientFactory.taskApi.clearAllTask(
                            agentId,
                            app.packageName
                        )
                        if (cleared) {
                            StaticToastMessageService.showSuccessToast("Tasks cleared")
                            shouldRefresh()
                            shouldRefreshTaskCount()
                        }
                    }.onFailure {
                        StaticToastMessageService.showErrorToast("Exception: ${it.message}")
                    }
                }
            }
        )
        TextButton(
            "Clear Fbr",
            backgroundColor = Colors.Red,
            hoverColor = Colors.Red.copy(alpha = 95),
            modifier = buttonModifier.onClick {
                GlobalScope.launch {
                    kotlin.runCatching {
                        val cleared = RestClientFactory.instagramApi.clearFollowBackRatio(
                            agentId,
                            app.packageName
                        )
                        if (cleared) {
                            StaticToastMessageService.showSuccessToast("Cleared Follow Back Ratio")
                            shouldRefresh()
                        }
                    }.onFailure {
                        StaticToastMessageService.showErrorToast("Exception: ${it.message}")
                    }
                }
            }
        )
    }
}

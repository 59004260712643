package com.jamshedalamqaderi.socialdroid.webapp.domain.utils

import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService

object ExceptionUtil {

    suspend inline fun <reified T> catch(
        showToast: Boolean = true,
        crossinline block: suspend () -> T
    ): T? {
        return try {
            block()
        } catch (e: Exception) {
            if (showToast) {
                StaticToastMessageService.showErrorToast("Exception: ${e.message}")
            }
            null
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramActionToolSetting
import com.jamshedalamqaderi.socialdroid.webapp.data.models.IntPair
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.TaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.IntPairExt.randomPair

class InstagramSettingSubmitService(
    private val agentId: String,
    private val instagramTool: InstagramTool
) : TaskSubmitService {

    var waitInSeconds: IntPair? = null
    var batchSize: IntPair? = null
    var taskPerDay: IntPair? = null
    var taskPerHour: IntPair? = null
    var sleep: IntPair? = null
    var manualSleep: Boolean? = null

    override suspend fun onSubmit() {
        val settingModel = InstagramActionToolSetting(
            batchSize?.randomPair(),
            waitInSeconds?.randomPair(),
            IntPair(10, 20, 15),
            taskPerDay?.randomPair(),
            taskPerHour?.randomPair(),
            sleep,
            manualSleep
        )
        catch {
            if (RestClientFactory.instagramApi.updateActionToolSetting(
                    agentId,
                    instagramTool,
                    settingModel
                )
            ) {
                StaticToastMessageService.showSuccessToast("Setting updated successfully")
            } else {
                StaticToastMessageService.showErrorToast("Setting updating failed")
            }
        }
    }

    fun loadFromSetting(settingModel: InstagramActionToolSetting) {
        batchSize = settingModel.batchSize
        waitInSeconds = settingModel.waitInSec
        taskPerDay = settingModel.taskPerDay
        taskPerHour = settingModel.taskPerHour
        sleep = settingModel.sleep
        manualSleep = settingModel.manualSleep
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.models.WorkdaysModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class WorkdaysSettingService : SingleResponsibilityService {
    companion object {
        val instance = WorkdaysSettingService()
    }

    val workdays = mutableStateOf<WorkdaysModel?>(null)

    override suspend fun refresh(params: Map<String, String?>) {
        val agentId = params["agentId"] ?: return
        if (agentId.isEmpty()) return
        kotlin.runCatching {
            workdays.value = null
            workdays.value = RestClientFactory.agentUserApi.getWorkdaysSetting(agentId)
        }
    }
}

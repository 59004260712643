package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramStrategy
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DashboardTask(
    val id: Long,
    val username: String,
    val message: String,
    val strategy: InstagramStrategy,
    val images: List<String>,
    val scraperDataLimit: Int,
    val appName: String,
    val dataCount: Int,
    val status: TaskState,
    val createdAt: LocalDateTime,
    val updatedAt: LocalDateTime
)

package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get

class DeviceAppApi(private val httpClient: HttpClient) : BaseApi("app/") {

    suspend fun dashboardAppList(deviceId: String): List<DashboardAppModel> {
        return httpClient
            .get(endpoint("dashboard_app_list/$deviceId"))
            .body<ResponseResult<List<DashboardAppModel>>>()
            .data ?: emptyList()
    }

    suspend fun updateAppName(appId: Long, appName: String): Boolean {
        return httpClient
            .get(endpoint("update_app_name/$appId/$appName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.exceptions.ApiException
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceUserModel
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceUserStatusModel
import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import com.jamshedalamqaderi.socialdroid.webapp.data.models.WorkdaysModel
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType

class DeviceUserApi(private val httpClient: HttpClient) : BaseApi("device_client/") {

    suspend fun allDevices(): List<DeviceUserModel> {
        return httpClient.get(endpoint("all_devices"))
            .body<ResponseResult<List<DeviceUserModel>>>()
            .data ?: emptyList()
    }

    suspend fun updateDeviceName(deviceId: String, newDeviceName: String): Boolean {
        return httpClient
            .get(endpoint("update_device_name/$deviceId/$newDeviceName"))
            .body<ResponseResult<String>>()
            .isSuccess
    }

    suspend fun deviceInfo(deviceId: String): DeviceUserModel? {
        return httpClient
            .get(endpoint("device/$deviceId"))
            .body<ResponseResult<DeviceUserModel>>()
            .data
    }

    suspend fun deleteDevice(deviceId: String): Boolean {
        return httpClient
            .get(endpoint("delete/$deviceId"))
            .body<ResponseResult<String>>()
            .isSuccess
    }

    suspend fun isDeviceExists(deviceId: String): Boolean {
        return httpClient
            .get(endpoint("device_exists/$deviceId"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun approveDevice(deviceId: String, approved: Boolean): Boolean {
        val response = httpClient
            .get(endpoint("approval/$deviceId/$approved"))
        return kotlin.runCatching {
            response.body<ResponseResult<Boolean>>()
                .data ?: false
        }.onFailure {
            throw ApiException(response.bodyAsText())
        }.getOrDefault(false)
    }

    suspend fun approvedDevices(): List<DeviceUserModel> {
        return httpClient
            .get(endpoint("approved_devices"))
            .body<ResponseResult<List<DeviceUserModel>>>()
            .data ?: emptyList()
    }

    suspend fun createOrUpdateWorkdaysSetting(deviceId: String, data: WorkdaysModel): Boolean {
        return httpClient
            .post(endpoint("workdays_setting/$deviceId")) {
                contentType(ContentType.Application.Json)
                setBody(data)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun getWorkdaysSetting(deviceId: String): WorkdaysModel? {
        return httpClient
            .get(endpoint("workdays_setting/$deviceId"))
            .body<ResponseResult<WorkdaysModel>>()
            .data
    }

    suspend fun agentStatus(agentId: String): DeviceUserStatusModel? {
        return httpClient
            .get(endpoint("device_status/$agentId"))
            .body<ResponseResult<DeviceUserStatusModel>>()
            .data
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Switch
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TextButton
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TextFieldStyle
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.LoginService
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@OptIn(DelicateCoroutinesApi::class)
@Page("/login")
@Composable
fun Login() {
    val context = rememberPageContext()
    val isLoggedIn = remember { mutableStateOf(true) }
    val username = remember { mutableStateOf("") }
    val password = remember { mutableStateOf("") }
    val remembered = remember { mutableStateOf(false) }
    LaunchedEffect(Unit) {
        isLoggedIn.value = LoginService.instance.isLoggedIn()
        if (isLoggedIn.value) {
            context.router.navigateTo("/")
        }
    }
    if (isLoggedIn.value) return
    Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
        Column(
            modifier = Modifier
                .width(350.px)
                .padding(10.px)
                .backgroundColor(Colors.White)
                .boxShadow(3.px, 3.px, 6.px, 2.px, Colors.Black.copy(alpha = 30))
                .borderRadius(5.px)
        ) {
            LoginForm(
                "Login",
                "Login",
                remembered = remembered,
                usernameChanged = {
                    username.value = it
                },
                passwordChanged = {
                    password.value = it
                },
                onRememberToggle = {
                    remembered.value = it
                },
                onSubmit = {
                    GlobalScope.launch {
                        if (LoginService.instance.login(
                                username.value,
                                password.value,
                                remembered.value
                            )
                        ) {
                            context.router.navigateTo("/")
                        }
                    }
                }
            )
        }
    }
}

@Composable
fun LoginForm(
    title: String,
    buttonText: String,
    remembered: MutableState<Boolean>? = null,
    usernameChanged: (String) -> Unit,
    passwordChanged: (String) -> Unit,
    onRememberToggle: ((Boolean) -> Unit)? = null,
    onSubmit: (() -> Unit)? = null
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SpanText(
            title,
            modifier = Modifier
                .fontSize(18.pt)
                .fontWeight(FontWeight.Bold)
        )
    }
    Gap(40.px)
    TextInputField(
        InputType.Text,
        "Username or Email",
        "Username or Email",
        modifier = Modifier.fillMaxWidth(),
        onValueChanged = {
            usernameChanged(it)
        }
    )
    Gap(20.px)
    TextInputField(
        InputType.Password,
        "Password",
        "Password",
        modifier = Modifier.fillMaxWidth(),
        onValueChanged = {
            passwordChanged(it)
        }
    )
    if (onRememberToggle != null) {
        Gap(20.px)
        RememberMe(remembered?.value ?: false, onSwitchToggle = {
            onRememberToggle.invoke(it)
        })
    }
    if (onSubmit != null) {
        Gap(20.px)
        TextButton(
            buttonText,
            backgroundColor = Colors.Blue.copy(alpha = 50),
            hoverColor = Colors.Blue.copy(alpha = 40),
            modifier = Modifier
                .fillMaxWidth()
                .height(40.px)
                .borderRadius(5.px)
                .onClick {
                    onSubmit()
                }
        )
    }
}

@Composable
fun TextInputField(
    inputType: InputType<*>,
    title: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    onValueChanged: (String) -> Unit = {}
) {
    val inputText = remember { mutableStateOf("") }
    Column(modifier = modifier) {
        SpanText(title, modifier = Modifier.fontSize(12.pt).fontWeight(FontWeight.Bold))
        Gap(5.px)
        Input(
            inputType,
            attrs = TextFieldStyle.toModifier()
                .outline(0.px, LineStyle.None)
                .fillMaxWidth()
                .minHeight(40.px)
                .padding(10.px)
                .borderRadius(5.px)
                .toAttrs {
                    placeholder(placeholder)
                    value(inputText.value)
                    onInput {
                        inputText.value = it.value?.toString() ?: ""
                        onValueChanged(inputText.value)
                    }
                }
        )
    }
}

@Composable
fun RememberMe(remembered: Boolean, onSwitchToggle: (Boolean) -> Unit = {}) {
    Row(
        modifier = Modifier
            .fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText("Remember me", modifier = Modifier.fontSize(12.pt).fontWeight(FontWeight.Bold))
        Switch(remembered, onSwitchToggle)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramToolsTableService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.WorkdaysSettingService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.WorkdaysSubmitService
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaPencil
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.CheckboxInput

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun WorkingDays(agentId: String) {
    val workdaysSettingService = remember { WorkdaysSettingService() }

    LaunchedEffect(agentId) {
        workdaysSettingService.refresh(mapOf("agentId" to agentId))
    }
    val showDialog = remember { mutableStateOf(false) }
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        SpanText(
            "WORKING DAYS",
            modifier = Modifier
                .fontSize(8.pt)
                .fontWeight(FontWeight.Bold)
                .color(Colors.Blue.copy(alpha = 80))
        )
        Gap(5.px)
        Row(modifier = Modifier.columnGap(5.px)) {
            Day("MON", workdaysSettingService.workdays.value?.mondayEnabled ?: false)
            Day("TUE", workdaysSettingService.workdays.value?.tuesdayEnabled ?: false)
            Day("WED", workdaysSettingService.workdays.value?.wednesdayEnabled ?: false)
            Day("THU", workdaysSettingService.workdays.value?.thursdayEnabled ?: false)
            Day("FRI", workdaysSettingService.workdays.value?.fridayEnabled ?: false)
            Day("SAT", workdaysSettingService.workdays.value?.saturdayEnabled ?: false)
            Day("SUN", workdaysSettingService.workdays.value?.sundayEnabled ?: false)
            EditButton {
                showDialog.value = true
            }
        }
    }
    if (showDialog.value) {
        val submitService = remember { WorkdaysSubmitService() }
        LaunchedEffect(agentId) {
            submitService.agentId = agentId
            submitService.loadFromWorkdaysModel(workdaysSettingService.workdays.value)
        }
        Dialog(
            onClose = {
                showDialog.value = false
            },
            onSubmit = {
                GlobalScope.launch {
                    submitService.onSubmit()
                    showDialog.value = false
                    workdaysSettingService.refresh(mapOf("agentId" to agentId))
                    InstagramToolsTableService.instance.refresh(mapOf("agentId" to agentId))
                }
            },
            submitButtonText = "Update"
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SpanText(
                    "Update Working Days",
                    modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold)
                )
                TextButton(
                    if (submitService.isSelectedAll()) "Deselect All" else "Select All",
                    backgroundColor = Colors.Blue.copy(alpha = 60),
                    hoverColor = Colors.Blue.copy(alpha = 55),
                    modifier = Modifier
                        .padding(leftRight = 6.px, topBottom = 3.px)
                        .borderRadius(5.px)
                        .onClick {
                            submitService.toggleSelectAll()
                        }
                )
            }
            Gap(20.px)
            DaySelectorItem(
                "Monday",
                submitService.monday.value,
                onSelected = { submitService.monday.value = it }
            )
            DaySelectorItem(
                "Tuesday",
                submitService.tuesday.value,
                onSelected = { submitService.tuesday.value = it }
            )
            DaySelectorItem(
                "Wednesday",
                submitService.wednesday.value,
                onSelected = { submitService.wednesday.value = it }
            )
            DaySelectorItem(
                "Thursday",
                submitService.thursday.value,
                onSelected = { submitService.thursday.value = it }
            )
            DaySelectorItem(
                "Friday",
                submitService.friday.value,
                onSelected = { submitService.friday.value = it }
            )
            DaySelectorItem(
                "Saturday",
                submitService.saturday.value,
                onSelected = { submitService.saturday.value = it }
            )
            DaySelectorItem(
                "Sunday",
                submitService.sunday.value,
                onSelected = { submitService.sunday.value = it }
            )
            Gap(30.px)
        }
    }
}

@Composable
fun Day(name: String, enabled: Boolean = false) {
    Box(
        modifier = Modifier
            .size(35.px)
            .borderRadius(50.percent)
            .backgroundColor(if (enabled) Colors.LimeGreen else Colors.Red)
            .boxShadow(2.px, 2.px, 2.px, 1.px, Colors.Black.copy(alpha = 20)),
        contentAlignment = Alignment.Center
    ) {
        SpanText(
            name,
            modifier = Modifier
                .fontSize(8.pt)
                .fontWeight(FontWeight.Bold)
                .color(Colors.WhiteSmoke)
        )
    }
}

@Composable
fun EditButton(onClick: () -> Unit = {}) {
    Box(
        modifier = Modifier
            .size(35.px)
            .borderRadius(50.percent)
            .backgroundColor(Colors.WhiteSmoke)
            .boxShadow(2.px, 2.px, 5.px, 1.px, Colors.Black.copy(alpha = 20))
            .onClick { onClick() }.cursor(Cursor.Pointer),
        contentAlignment = Alignment.Center
    ) {
        FaPencil(modifier = Modifier.color(Colors.Grey))
    }
}

val DaySelectorHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Transparent)
    }
    hover {
        Modifier.backgroundColor(Colors.Blue.copy(alpha = 20))
    }
}

@Composable
fun DaySelectorItem(name: String, selected: Boolean = false, onSelected: (Boolean) -> Unit = {}) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(30.px)
            .padding(5.px)
            .borderRadius(3.px)
            .then(DaySelectorHoverStyle.toModifier()),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(name)
        CheckboxInput(
            selected,
            attrs = Modifier.toAttrs {
                onChange {
                    onSelected(it.value)
                }
            }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.layouts

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramFollowTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramHashTagUsersScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramLocationUsersScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramMessageTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramOwnFollowersScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramOwnFollowingsScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramPostTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramUnfollowTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramUserContactsScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramUserFollowersScraperTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks.InstagramUserFollowingsScraperCreateTaskLayout
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool

@Composable
fun InstagramTaskCreateLayout(agentId: String, instagramTool: InstagramTool) {
    when (instagramTool) {
        InstagramTool.IG_FOLLOW -> InstagramFollowTaskCreateLayout(agentId)
        InstagramTool.IG_UNFOLLOW -> InstagramUnfollowTaskCreateLayout(agentId)
        InstagramTool.IG_MESSAGE -> InstagramMessageTaskCreateLayout(agentId)
        InstagramTool.IG_POSTING -> InstagramPostTaskCreateLayout(agentId)
        InstagramTool.IG_USER_FOLLOWERS_SCRAPER -> InstagramUserFollowersScraperTaskCreateLayout(agentId)
        InstagramTool.IG_USER_FOLLOWINGS_SCRAPER -> InstagramUserFollowingsScraperCreateTaskLayout(agentId)
        InstagramTool.IG_USER_CONTACTS_SCRAPER -> InstagramUserContactsScraperCreateTaskLayout(agentId)
        InstagramTool.IG_LOCATION_USERS_SCRAPER -> InstagramLocationUsersScraperCreateTaskLayout(agentId)
        InstagramTool.IG_HASHTAG_USERS_SCRAPER -> InstagramHashTagUsersScraperCreateTaskLayout(agentId)
        InstagramTool.IG_FOLLOWERS_SCRAPE -> InstagramOwnFollowersScraperCreateTaskLayout(agentId)
        InstagramTool.IG_FOLLOWING_SCRAPE -> InstagramOwnFollowingsScraperCreateTaskLayout(agentId)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramPostingToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.TaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.files.File
import org.w3c.files.FileReader

open class InstagramPostTaskSubmitService : TaskSubmitService {
    companion object {
        val instance = InstagramPostTaskSubmitService()
    }

    var appPackageNames = listOf<String>()
    var agentId: String = ""
    var spintaxMessage: String = ""
    var images: List<File> = emptyList()

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }

        val uploadedImages = images.mapNotNull {
            kotlin.runCatching {
                RestClientFactory.imageApi.uploadImage(
                    it.name,
                    it.type,
                    it.toByteArray()
                )
            }.getOrNull()
        }
        if (images.size != uploadedImages.size) {
            // Rollback
            StaticToastMessageService.showErrorToast("One or more image uploading failed. Deleting uploaded images...")
            // delete all uploaded images
            val deleteResult = uploadedImages.map {
                kotlin.runCatching { RestClientFactory.imageApi.deleteImage(it) }
                    .getOrElse { false }
            }.reduce { acc, b -> acc && b }
            if (deleteResult) {
                StaticToastMessageService.showErrorToast("Uploaded images deleting failed")
            }
            return
        }

        val postingTaskModel = InstagramPostingToolDto(
            agentId,
            appPackageNames,
            uploadedImages,
            spintaxMessage
        )
        catch {
            val result = RestClientFactory.instagramApi.createPostingBot(postingTaskModel)
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }

    private suspend fun File.toByteArray() = suspendCancellableCoroutine<ByteArray> { cont ->
        val reader = FileReader()
        reader.onload = {
            val byteArray = reader.result.toString().map { it.code.toByte() }.toByteArray()
            cont.resumeWith(Result.success(byteArray))
        }
        reader.onerror = {
            cont.cancel(reader.error as Throwable)
        }
        reader.readAsBinaryString(this)
    }
}

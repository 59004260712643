package com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ImageSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramAppsSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.SpintaxMessageInput
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TaskSubmitButton
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramPostTaskSubmitService
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramPostTaskCreateLayout(agentId: String) {
    val postTaskSubmitService = remember { InstagramPostTaskSubmitService() }
    LaunchedEffect(agentId) {
        postTaskSubmitService.agentId = agentId
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        InstagramAppsSelector(agentId, onSelected = {
            postTaskSubmitService.appPackageNames = it
        })
        Gap(20.px)
        ImageSelector(onImageChanged = { selectedImages ->
            postTaskSubmitService.images = selectedImages
        })
        Gap(20.px)
        SpintaxMessageInput(onChange = {
            postTaskSubmitService.spintaxMessage = it
        })
        Gap(20.px)
        TaskSubmitButton(onClick = {
            GlobalScope.launch {
                postTaskSubmitService.onSubmit()
            }
        })
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Tbody
import org.jetbrains.compose.web.dom.Thead
import org.jetbrains.compose.web.dom.Tr

val InstagramToolsTableHoverStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(Colors.White)
            .cursor(Cursor.Pointer)
    }

    cssRule(":hover") {
        Modifier.backgroundColor(Colors.Black.copy(alpha = 5))
    }
}

@Composable
inline fun <reified T> Table(
    dataList: List<T>,
    headers: List<@Composable () -> Unit>,
    cells: List<@Composable (Int, T) -> Unit>,
    emptyText: String = "Empty",
    crossinline onRowClicked: (T) -> Unit = {}
) {
    org.jetbrains.compose.web.dom.Table(attrs = {
        style {
            width(100.percent)
            borderWidth(0.px)
            property("border-collapse", "collapse")
        }
    }) {
        Thead(
            attrs = {
                style {
                    backgroundColor(Colors.Blue.copy(alpha = 15))
                }
            }
        ) {
            Tr {
                headers.forEach {
                    it.invoke()
                }
            }
        }
        Tbody(attrs = {
            style {
                backgroundColor(Colors.White)
            }
        }) {
            dataList.forEachIndexed { index, dataModel ->

                Tr(
                    attrs = InstagramToolsTableHoverStyle
                        .toModifier()
                        .then(
                            Modifier.onClick {
                                onRowClicked(dataModel)
                            }
                        )
                        .toAttrs()
                ) {
                    cells.forEach {
                        it.invoke(index, dataModel)
                    }
                }
            }
        }
    }
    if (dataList.isEmpty()) {
        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 60.px, bottom = 10.px)
        ) {
            SpanText(emptyText, modifier = Modifier.color(Colors.LightGrey))
        }
    }
}

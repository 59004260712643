package com.jamshedalamqaderi.socialdroid.webapp.data.enums

enum class DeviceUserStatus(val tag: String, val text: String) {
    IDLE("IDLE", "Idle"),
    FOLLOWING(InstagramTool.IG_FOLLOW.name, "Following"),
    UNFOLLOWING(InstagramTool.IG_UNFOLLOW.name, "Unfollowing"),
    SENDING_MESSAGE(InstagramTool.IG_MESSAGE.name, "Sending DM"),
    POSTING(InstagramTool.IG_POSTING.name, "Making a post"),
    SCRAPE_FOLLOWERS(InstagramTool.IG_FOLLOWERS_SCRAPE.name, "Scraping own followers"),
    SCRAPE_FOLLOWINGS(InstagramTool.IG_FOLLOWING_SCRAPE.name, "Scraping own followings"),
    SCRAPING_USERS_BY_LOCATION(
        InstagramTool.IG_LOCATION_USERS_SCRAPER.name,
        "Scraping by location"
    ),
    SCRAPING_USERS_BY_HASHTAG(InstagramTool.IG_HASHTAG_USERS_SCRAPER.name, "Scraping by #tag"),
    SCRAPING_USER_CONTACTS(InstagramTool.IG_USER_CONTACTS_SCRAPER.name, "Scraping contacts"),
    SCRAPING_USER_FOLLOWINGS(
        InstagramTool.IG_USER_FOLLOWINGS_SCRAPER.name,
        "Scraping followings"
    ),
    SCRAPING_USER_FOLLOWERS(InstagramTool.IG_USER_FOLLOWERS_SCRAPER.name, "Scraping followers");

    companion object {
        fun valueOrNull(tag: String): DeviceUserStatus? {
            return values().find { it.tag == tag }
        }
    }
}

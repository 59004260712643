package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.overlay.Overlay
import org.jetbrains.compose.web.css.px

@Composable
fun Dialog(
    modifier: Modifier = Modifier,
    onClose: (() -> Unit)? = {},
    onSubmit: (() -> Unit)? = {},
    closeButtonText: String = "Close",
    submitButtonText: String = "Submit",
    actions: @Composable () -> Unit = {},
    content: @Composable () -> Unit
) {
    Overlay(
        contentAlignment = Alignment.Center,
        modifier = Modifier.fillMaxSize()
    ) {
        Box(
            modifier = Modifier
                .minWidth(500.px)
                .minHeight(200.px)
                .backgroundColor(Colors.White)
                .borderRadius(10.px)
                .padding(10.px)
                .then(modifier)
        ) {
            Column(Modifier.fillMaxSize()) {
                Column(Modifier.fillMaxSize()) {
                    content()
                }
                Row(
                    Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.End
                ) {
                    actions()
                    if (onClose != null) {
                        TextButton(
                            closeButtonText,
                            modifier = Modifier
                                .padding(topBottom = 5.px, leftRight = 10.px)
                                .borderRadius(5.px)
                                .cursor(Cursor.Pointer)
                                .onClick { onClose() },
                            backgroundColor = Colors.WhiteSmoke,
                            hoverColor = Colors.WhiteSmoke.copy(alpha = 80)
                        )
                        Gap(10.px)
                    }
                    if (onSubmit != null) {
                        TextButton(
                            submitButtonText,
                            modifier = Modifier
                                .padding(topBottom = 5.px, leftRight = 10.px)
                                .borderRadius(5.px)
                                .cursor(Cursor.Pointer)
                                .onClick { onSubmit() },
                            backgroundColor = Colors.Blue.copy(alpha = 60),
                            hoverColor = Colors.Blue.copy(alpha = 40)
                        )
                        Gap(10.px)
                    }
                }
            }
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.layouts

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.BuildKonfig
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.MainMenuOption
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.LongExt.toLocalDate
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflowX
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

val hideScrollbarStyle by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("-ms-overflow-style", "none")
            property("scrollbar-width", "none")
        }
    }
    cssRule("::-webkit-scrollbar") {
        Modifier.display(DisplayStyle.None)
    }
}

@Composable
fun MainMenuLayout(selectedOption: SocialdroidMainMenuOptions, body: @Composable () -> Unit) {
    Row(modifier = Modifier.fillMaxSize()) {
        Column(
            modifier = Modifier
                .backgroundColor(Colors.White)
                .width(280.px)
                .height(100.vh)
                .overflowX(Overflow.Hidden)
                .overflowY(Overflow.Auto)
                .position(Position.Sticky)
                .top(0.px)
                .then(hideScrollbarStyle.toModifier())
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Image("/socialdroid-logo.png", modifier = Modifier.margin(top = 20.px))
                SpanText(
                    "socialdroid",
                    modifier = Modifier
                        .fontWeight(FontWeight.ExtraBold)
                        .fontSize(24.pt)
                        .margin(top = 10.px)
                )
                Gap(10.px)
                SpanText(
                    "v${BuildKonfig.version}",
                    modifier = Modifier
                        .fontSize(10.pt)
                        .color(Colors.Blue.copy(alpha = 90))
                )
                SpanText(
                    BuildKonfig.buildTime.toLocalDate(),
                    modifier = Modifier
                        .fontSize(10.pt)
                        .color(Colors.Blue.copy(alpha = 90))
                )
            }
            Gap(30.px)
            SpanText(
                "MAIN MENU",
                modifier = Modifier.margin(left = 10.px).fontWeight(FontWeight.Bold)
            )
            Column(
                modifier = Modifier.margin(10.px).fillMaxWidth()
            ) {
                MainMenuOption(
                    iconName = "house",
                    optionName = "Home",
                    route = "/",
                    selected = selectedOption == SocialdroidMainMenuOptions.Home
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "robot",
                    optionName = "Instagram Tools",
                    route = "/instagram-tools/${DeviceService.instance.devices.value.firstOrNull()?.deviceId}",
                    selected = selectedOption == SocialdroidMainMenuOptions.InstagramTool
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "download",
                    optionName = "Download Agent",
                    route = "/download-agent",
                    selected = selectedOption == SocialdroidMainMenuOptions.DownloadAgent
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "link",
                    optionName = "Connect Agent",
                    route = "/connect-agent",
                    selected = selectedOption == SocialdroidMainMenuOptions.ConnectAgent
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "circle-question",
                    optionName = "Tutorials",
                    route = "/tutorials",
                    selected = selectedOption == SocialdroidMainMenuOptions.Tutorials
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "cart-shopping",
                    optionName = "Buy More Servers",
                    route = "/buy-more-servers",
                    selected = selectedOption == SocialdroidMainMenuOptions.BuyMoreServers
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "envelope",
                    optionName = "Mass Emailer Software",
                    route = "/mass-emailer-software",
                    selected = selectedOption == SocialdroidMainMenuOptions.MassEmailSoftware
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "ticket",
                    optionName = "Support",
                    route = "/contact-support",
                    selected = selectedOption == SocialdroidMainMenuOptions.ContactSupport
                )
                Gap(10.px)
                MainMenuOption(
                    iconName = "cogs",
                    optionName = "Update Logs",
                    route = "/update-logs",
                    selected = selectedOption == SocialdroidMainMenuOptions.UpdateLogs
                )
            }
        }
        body()
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramUsernameInputService

@Composable
fun InstagramUsernamesInput() {
    val text = remember { mutableStateOf("") }
    TextField(
        text.value,
        "Input Usernames",
        "Press 'Enter' to save (separator: `,` & ` `)",
        onValueChange = { newValue ->
            if (InstagramUsernameInputService.instance.onUsernameInput(newValue)) {
                text.value = ""
            } else {
                text.value = newValue
            }
        }
    )
}

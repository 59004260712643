package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaPencil
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun AgentNameEdit(agentId: String, agentName: String, shouldRefresh: () -> Unit = {}) {
    val showAgentNameEditDialog = remember { mutableStateOf(false) }
    val updatedAgentName = remember { mutableStateOf(agentName) }
    FaPencil(
        modifier = Modifier
            .padding(5.px)
            .color(Colors.Blue.copy(alpha = 70))
            .cursor(Cursor.Pointer)
            .onClick {
                showAgentNameEditDialog.value = true
            }
    )

    if (showAgentNameEditDialog.value) {
        Dialog(
            onClose = {
                showAgentNameEditDialog.value = false
            },
            onSubmit = {
                if (updatedAgentName.value != agentName) {
                    GlobalScope.launch {
                        catch {
                            if (RestClientFactory.agentUserApi.updateDeviceName(
                                    agentId,
                                    updatedAgentName.value
                                )
                            ) {
                                StaticToastMessageService.showSuccessToast("Agent name updated successfully")
                                shouldRefresh()
                            } else {
                                StaticToastMessageService.showErrorToast("Update failed")
                            }
                        }
                        showAgentNameEditDialog.value = false
                    }
                } else {
                    showAgentNameEditDialog.value = false
                }
            },
            submitButtonText = "Update"
        ) {
            TextField(updatedAgentName.value, "Agent Name", "Agent Name", onValueChange = {
                updatedAgentName.value = it
            })
            Gap(20.px)
        }
    }
}

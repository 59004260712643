package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun TaskStatistic(title: String, barColor: CSSColorValue, total: Int, progress: Int) {
    Column(
        modifier = Modifier
            .padding(10.px)
            .fillMaxWidth(32.percent)
            .backgroundColor(Colors.White)
            .borderRadius(10.px)
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            SpanText(title)
            SpanText(
                "$progress",
                modifier = Modifier
                    .fontSize(24.pt)
                    .fontWeight(FontWeight.Bold)
            )
        }
        Gap(20.px)
        LinearProgressIndicator(
            modifier = Modifier
                .fillMaxWidth()
                .height(5.px)
                .borderRadius(20.px),
            barColor,
            progress / total.toDouble()
        )
    }
}

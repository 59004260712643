package com.jamshedalamqaderi.socialdroid.webapp.data.remote.ws

import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceWebsocketEvent
import com.jamshedalamqaderi.socialdroid.webapp.data.serverAddress
import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.websocket.WebSockets
import io.ktor.client.plugins.websocket.webSocketSession
import io.ktor.serialization.kotlinx.json.json
import io.ktor.websocket.Frame
import io.ktor.websocket.WebSocketSession
import io.ktor.websocket.readText
import kotlinx.datetime.Clock
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

private typealias WebSocketListener = suspend (DeviceWebsocketEvent) -> Unit

object WebSocketClientFactory {
    private var session: WebSocketSession? = null
    private val listeners = hashMapOf<String, WebSocketListener>()
    private val userId = Clock.System.now().toEpochMilliseconds()

    suspend fun connect() {
        session = wsClient.webSocketSession("ws://${serverAddress()}/ws/webpages_events/$userId")
        while (true) {
            when (val frame = session?.incoming?.receive()) {
                is Frame.Text -> {
                    val msg = frame.readText()
                    try {
                        val eventModel = Json.decodeFromString<DeviceWebsocketEvent>(msg)
                        broadcastToAll(eventModel)
                    } catch (e: Exception) {
                    }
                }

                is Frame.Close -> {}
                else -> {
                    continue
                }
            }
        }
    }

    fun listen(key: String, callback: WebSocketListener) {
        if (listeners.containsKey(key)) {
            throw Exception("WebSocket event listener already exists with key: $key")
        }
        listeners[key] = callback
    }

    fun remove(key: String) {
        if (listeners.containsKey(key)) {
            listeners.remove(key)
        }
    }
    suspend fun send(event: DeviceWebsocketEvent) {
        try {
            session?.send(Frame.Text(Json.encodeToString(event)))
        } catch (e: Exception) {
            println("Exception occurred while sending ws event")
        }
    }

    private suspend fun broadcastToAll(event: DeviceWebsocketEvent) {
        listeners.values.forEach {
            it(event)
        }
    }

    private val wsClient = HttpClient {
        install(ContentNegotiation) {
            json(Json { ignoreUnknownKeys = true })
        }
        install(WebSockets)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InstagramContactDataModel(
    @SerialName("ig_title")
    val title: String,
    @SerialName("ig_value")
    val value: String
)

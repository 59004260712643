package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramHashTagInputService

@Composable
fun InstagramHashTagInput() {
    val text = remember { mutableStateOf("") }
    TextField(
        text.value,
        "Input #Tags",
        "Press 'Enter' to save (eg: `#tag1, #tag2`) (separator: `,` & ` `)",
        onValueChange = { newValue ->
            if (InstagramHashTagInputService.instance.onHashTagInput(newValue)) {
                text.value = ""
            } else {
                text.value = newValue
            }
        }
    )
}

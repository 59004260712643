package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardHomeBody
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun DashboardHome() {
    MainMenuLayout(SocialdroidMainMenuOptions.Home) {
        DashboardBodyLayout("Dashboard") {
            DashboardHomeBody()
        }
    }
}

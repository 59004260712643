package com.jamshedalamqaderi.socialdroid.webapp.data

import com.jamshedalamqaderi.socialdroid.webapp.BuildKonfig
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.LoginService
import kotlinx.browser.window

actual fun serverAddress(): String {
    return if (BuildKonfig.environment == "RELEASE") {
        window.location.host
    } else {
        "${window.location.hostname}:8080"
    }
}

actual fun loadJwtToken(): String {
    return LoginService.instance.token() ?: ""
}

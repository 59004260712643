package com.jamshedalamqaderi.socialdroid.webapp

import kotlin.Int
import kotlin.Long
import kotlin.String

internal object BuildKonfig {
  public val environment: String = "RELEASE"

  public val version: String = "0.2.0"

  public val buildTime: Long = 1703596176151

  public val buildNumber: Int = 30
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardTask
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class DashboardTasksTableService : SingleResponsibilityService {
    val tasks = mutableStateOf(listOf<DashboardTask>())
    val pageCount = mutableStateOf(0)
    val selectedTasks = mutableStateListOf<Long>()

    val isSelectedAll = mutableStateOf(false)
    val currentPage = mutableStateOf(1)

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            val agentId = params["agentId"] ?: return
            val instagramTool = InstagramTool.valueOrNull(params["instagramTool"]) ?: return
            val limit = params["limit"]?.toInt() ?: 50
            val appPackageName = params["appPackageName"]
            tasks.value = RestClientFactory
                .instagramApi
                .getDashboardTasks(
                    agentId,
                    instagramTool,
                    currentPage.value - 1,
                    limit,
                    packageName = appPackageName
                )
            val totalTasks = RestClientFactory.taskApi.countTasks(agentId, instagramTool.name)
            pageCount.value = if (totalTasks == 0) 0 else totalTasks / 50 + 1
            selectedTasks.clear()
        }
    }

    fun onTaskSelection(taskId: Long) {
        if (selectedTasks.contains(taskId)) {
            selectedTasks.remove(taskId)
            return
        }
        if (tasks.value.find { it.id == taskId } != null) {
            selectedTasks.add(taskId)
        }
        updateSelectAllStatus()
    }

    fun onSelectAll() {
        if (isSelectedAll.value) {
            selectedTasks.clear()
        } else {
            selectedTasks.addAll(
                tasks
                    .value
                    .filter { !selectedTasks.contains(it.id) }
                    .map { it.id }
            )
        }
        updateSelectAllStatus()
    }

    private fun updateSelectAllStatus() {
        isSelectedAll.value =
            if (tasks.value.isEmpty()) {
                false
            } else {
                tasks.value.map { selectedTasks.contains(it.id) }
                    .reduce { acc, b -> acc && b }
            }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramActionToolSettingService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramSettingSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.DateTimeExt.toLocalTime
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import kotlinx.datetime.LocalTime
import org.jetbrains.compose.web.css.px

@Composable
fun InstagramToolSetting(
    agentId: String,
    instagramTool: InstagramTool,
    instagramSettingSubmitService: InstagramSettingSubmitService?
) {
    val instagramActionToolSettingService = remember { InstagramActionToolSettingService() }

    LaunchedEffect(instagramTool) {
        instagramActionToolSettingService.refresh(
            mapOf(
                "agentId" to agentId,
                "instagramTool" to instagramTool.name
            )
        )
        val setting = instagramActionToolSettingService.setting.value
        if (setting != null) {
            instagramSettingSubmitService?.loadFromSetting(setting)
        }
    }
    if (instagramActionToolSettingService.setting.value == null) return
    Column(modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)) {
        val model = instagramActionToolSettingService.setting.value
        SettingOption(
            "Wait between operations (in seconds)",
            model?.waitInSec?.first ?: 0,
            model?.waitInSec?.second ?: 0,
            onChange = {
                instagramSettingSubmitService?.waitInSeconds = it
            }
        )
        SettingOption(
            "${instagramTool.toolName}s per operation",
            model?.batchSize?.first ?: 0,
            model?.batchSize?.second ?: 0,
            onChange = {
                instagramSettingSubmitService?.batchSize = it
            }
        )
        SettingOption(
            "Number of users to ${instagramTool.toolName} per day",
            model?.taskPerDay?.first ?: 0,
            model?.taskPerDay?.second ?: 0,
            onChange = {
                instagramSettingSubmitService?.taskPerDay = it
            }
        )
        SettingOption(
            "Number of users to ${instagramTool.toolName} per hour",
            model?.taskPerHour?.first ?: 0,
            model?.taskPerHour?.second ?: 0,
            onChange = {
                instagramSettingSubmitService?.taskPerHour = it
            }
        )
        SettingOption(
            "Sleep between",
            model?.sleep?.first?.toLocalTime() ?: LocalTime(0, 0),
            model?.sleep?.second?.toLocalTime() ?: LocalTime(0, 0),
            onChange = {
                instagramSettingSubmitService?.sleep = it
            }
        )
        SettingOption(
            "Manual sleep",
            model?.manualSleep ?: false,
            onChange = {
                instagramSettingSubmitService?.manualSleep = it
            }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.right
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.w3c.dom.url.URL
import org.w3c.files.File
import org.w3c.files.get

val ImagePreviewHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Red)
    }
    cssRule(":hover") {
        Modifier.backgroundColor(Colors.Red.copy(alpha = 80))
    }
}

@Composable
fun ImageSelector(
    onImageChanged: (List<File>) -> Unit = {}
) {
    val postImages = remember { mutableStateListOf<File>() }
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth(),
//            horizontalArrangement = Arrangement.SpaceBetween
            verticalAlignment = Alignment.CenterVertically
        ) {
            SpanText(
                "Images",
                modifier = Modifier.fontSize(18.pt).fontWeight(FontWeight.Bold)
            )
            Gap(20.px)
            FilePickerButton { selectedFiles ->
                postImages.addAll(selectedFiles)
                onImageChanged(postImages)
            }
        }
        Gap(10.px)

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .flexWrap(FlexWrap.Wrap)
                .columnGap(10.px)
                .rowGap(10.px)
        ) {
            if (postImages.isEmpty()) {
                SpanText("No Images Selected", modifier = Modifier.padding(20.px))
            } else {
                postImages.forEach { file ->
                    Box(
                        modifier = Modifier.size(200.px).cursor(Cursor.Pointer)
                            .position(Position.Relative)
                    ) {
                        FaXmark(
                            modifier = Modifier
                                .position(Position.Absolute)
                                .top(5.px).right(5.px)
                                .padding(10.px)
                                .borderRadius(3.px)
                                .color(Colors.White)
                                .then(ImagePreviewHoverStyle.toModifier())
                                .onClick {
                                    postImages.remove(file)
                                    if (postImages.isEmpty()) {
                                        postImages.clear()
                                    }
                                    onImageChanged(postImages)
                                }
                        )
                        Image(
                            URL.createObjectURL(file),
                            desc = file.name,
                            modifier = Modifier
                                .fillMaxSize()
                                .borderRadius(5.px)
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun FilePickerButton(
    onImagePicked: (List<File>) -> Unit = {}
) {
    val postImagePickerHovered = remember { mutableStateOf(false) }
    Column {
        Input(
            InputType.File,
            attrs = Modifier
                .toAttrs {
                    id("post-image-picker")
                    accept("image/*")
                    hidden()
                    multiple()
                    onChange {
                        val files = it.target.files ?: return@onChange
                        val imageList = arrayListOf<File>()
                        for (i in 0 until files.length) {
                            val file = files[i]
                            if (file != null) {
                                imageList.add(file)
                            }
                        }
                        onImagePicked(imageList)
                    }
                }
        )
        Label(
            "post-image-picker",
            attrs = Modifier
                .cursor(Cursor.Pointer)
                .onHover { hovered ->
                    postImagePickerHovered.value = hovered
                }
                .toAttrs()
        ) {
            TextButton(
                "Pick Images",
                backgroundColor = Colors.Blue.copy(alpha = 50),
                hoverColor = Colors.Blue.copy(alpha = 30),
                modifier = Modifier
                    .fontSize(10.pt)
                    .padding(leftRight = 15.px, topBottom = 5.px)
                    .borderRadius(3.px)
            )
        }
    }
}

fun Modifier.onHover(callback: (Boolean) -> Unit) = attrsModifier {
    onMouseEnter {
        callback(true)
    }

    onMouseLeave {
        callback(false)
    }
}

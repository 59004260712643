package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramToolTaskDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramUnfollowAllFollowingsToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch

class InstagramUnfollowTaskSubmitService : InstagramFollowTaskSubmitService() {
    companion object {
        val instance = InstagramUnfollowTaskSubmitService()
    }

    var unfollowAllFollowings = false

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }
        catch {
            val result = if (unfollowAllFollowings) {
                submitUnfollowAllFollowings()
            } else {
                submitUnfollowTasks()
            }
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
                InstagramUsernameInputService.instance.usernames.clear()
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }

    private suspend fun submitUnfollowAllFollowings(): Boolean {
        val task = InstagramUnfollowAllFollowingsToolDto(strategy, appPackageNames)
        return RestClientFactory.instagramApi.createUnfollowAllFollowingsBot(agentId, task)
    }

    private suspend fun submitUnfollowTasks(): Boolean {
        if (InstagramUsernameInputService.instance.usernames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No usernames has been given")
            throw IllegalStateException("Username list is empty")
        }
        val tasks = arrayListOf<InstagramToolTaskDto>()
        InstagramUsernameInputService.instance.usernames.forEach { username ->
            appPackageNames.forEach { packageName ->
                tasks.add(InstagramToolTaskDto(username, packageName, strategy))
            }
        }
        return RestClientFactory.instagramApi.createUnfollowBot(agentId, tasks)
    }
}

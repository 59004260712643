package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.ToolType
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramSettingCopyService
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramCopyToDialog(
    showCopyToDialog: MutableState<Boolean>
) {
    val isAllAgentSelected = remember { mutableStateOf(true) }
    val selectedAgentId = remember { mutableStateOf("") }

    val isAllToolSelected = remember { mutableStateOf(true) }
    val selectedTool = remember { mutableStateOf("") }
    Dialog(
        onClose = {
            showCopyToDialog.value = false
        },
        onSubmit = {
            GlobalScope.launch {
                val selectedAgents = if (isAllAgentSelected.value) {
                    DeviceService.instance.devices.value.mapNotNull { it.deviceId }
                } else if (selectedAgentId.value.isNotEmpty()) {
                    listOf(selectedAgentId.value)
                } else {
                    listOf()
                }
                val selectedTools = if (isAllToolSelected.value) {
                    InstagramTool.values().filter { it.toolType == ToolType.ACTION }.toList()
                } else if (selectedTool.value.isNotEmpty()) {
                    listOf(InstagramTool.valueOf(selectedTool.value))
                } else {
                    listOf()
                }

                selectedAgents.forEach { agentId ->
                    selectedTools.forEach { tool ->
                        InstagramSettingCopyService
                            .instance
                            .pasteSetting(
                                agentId,
                                tool
                            )
                    }
                }
            }
            showCopyToDialog.value = false
        }
    ) {
        SpanText("Copy To", modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold))
        Gap(20.px)
        Row(modifier = Modifier.fillMaxSize()) {
            Column(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
                CopyToItem("All", selected = isAllAgentSelected.value, onClicked = {
                    isAllAgentSelected.value = true
                    selectedAgentId.value = ""
                })
                DeviceService.instance.devices.value.forEach { deviceUserModel ->
                    CopyToItem(
                        deviceUserModel.deviceName ?: "",
                        selected = selectedAgentId.value == deviceUserModel.deviceId,
                        onClicked = {
                            selectedAgentId.value = deviceUserModel.deviceId ?: ""
                            isAllAgentSelected.value = false
                        }
                    )
                }
            }
            Gap(5.px)
            Column(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
                CopyToItem("All", selected = isAllToolSelected.value, onClicked = {
                    isAllToolSelected.value = true
                    selectedTool.value = ""
                })
                InstagramTool
                    .values()
                    .filter { it.toolType == ToolType.ACTION }
                    .forEach { tool ->
                        CopyToItem(
                            tool.toolName,
                            selected = selectedTool.value == tool.name,
                            onClicked = {
                                selectedTool.value = tool.name
                                isAllToolSelected.value = false
                            }
                        )
                    }
            }
        }
        Gap(20.px)
    }
}

@Composable
fun CopyToItem(
    value: String,
    selected: Boolean = false,
    onClicked: () -> Unit = {}
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(30.px)
            .border(1.px, LineStyle.Solid, Colors.WhiteSmoke)
            .padding(3.px)
            .backgroundColor(if (selected) Colors.Blue.copy(alpha = 50) else Colors.White)
            .onClick { onClicked() }
            .cursor(Cursor.Pointer),
        contentAlignment = Alignment.CenterStart
    ) {
        SpanText(value)
    }
}

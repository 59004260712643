package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.IgAppState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.ToolType
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.Tool

class InstagramToolsTableService : SingleResponsibilityService {
    companion object {
        val instance = InstagramToolsTableService()
    }

    val actionTools = mutableStateOf<List<Tool>>(emptyList())
    val scraperTools = mutableStateOf<List<Tool>>(emptyList())

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            val agentId = params["agentId"] ?: return
            filterAndSetTools(
                InstagramTool.supportedTools.map { instagramTool ->
                    Tool(
                        instagramTool.name,
                        instagramTool.toolName,
                        0,
                        0,
                        0,
                        0,
                        IgAppState.SLEEPING
                    )
                }
            )
            val igTools = InstagramTool.supportedTools.map { instagramTool ->
                Tool(
                    instagramTool.name,
                    instagramTool.toolName,
                    RestClientFactory.taskApi.countTasks(
                        agentId,
                        instagramTool.name,
                        TaskState.Queued
                    ),
                    RestClientFactory.taskApi.countTasks(
                        agentId,
                        instagramTool.name,
                        TaskState.Failed
                    ),
                    RestClientFactory.taskApi.countTasks(
                        agentId,
                        instagramTool.name,
                        TaskState.Completed
                    ),
                    RestClientFactory.taskApi.countTasks(agentId, instagramTool.name),
                    if (instagramTool.toolType == ToolType.SCRAPER) {
                        IgAppState.WORKING
                    } else {
                        getBotStatus(
                            agentId,
                            instagramTool
                        )
                    }
                )
            }
            filterAndSetTools(igTools)
        }
    }

    private fun filterAndSetTools(tools: List<Tool>) {
        actionTools.value =
            tools.filter { InstagramTool.valueOf(it.toolIndex).toolType == ToolType.ACTION }
                .toList()
        scraperTools.value =
            tools.filter { InstagramTool.valueOf(it.toolIndex).toolType == ToolType.SCRAPER }
                .toList()
    }

    private suspend fun getBotStatus(agentId: String, instagramTool: InstagramTool): IgAppState {
        return try {
            RestClientFactory
                .instagramApi
                .getActionBotStatus(
                    agentId,
                    instagramTool
                )
        } catch (e: Exception) {
            e.printStackTrace()
            IgAppState.SLEEPING
        }
    }
}

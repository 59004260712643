package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.DeviceUserStatus
import kotlinx.serialization.Serializable

@Serializable
data class DeviceUserStatusModel(
    val status: DeviceUserStatus,
    val appName: String
)

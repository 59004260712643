package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Input

@Composable
fun Checkbox(checked: Boolean, modifier: Modifier = Modifier) {
    Input(
        InputType.Checkbox,
        attrs = modifier.toAttrs {
            checked(checked)
        }
    )
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class DeviceAppService : SingleResponsibilityService {
    val apps = mutableStateOf<List<DashboardAppModel>>(emptyList())

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            if (params.containsKey("agentId")) {
                val agentId = params["agentId"]
                if (agentId != null) {
                    apps.value = RestClientFactory.deviceAppApi.dashboardAppList(agentId)
                        .sortedBy { it.packageName }
                }
            }
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class InstagramPostingToolDto(
    val deviceId: String,
    val packageNames: List<String>,
    val images: List<String>,
    val message: String
)

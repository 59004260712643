package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool

@Composable
fun InstagramToolSelector(
    selectedToolType: InstagramTool,
    instagramTools: List<InstagramTool>,
    onToolSelected: (InstagramTool) -> Unit = {}
) {
    DropdownOption(
        selectedToolType.name,
        options = instagramTools,
        index = {
            it.name
        },
        title = {
            it.toolName
        },
        subtitle = {
            it.toolType.name
        },
        onOptionSelected = {
            onToolSelected(InstagramTool.valueOrNull(it.name)!!)
        }
    )
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun TaskCreateOption(selected: Boolean, title: String, onSelection: () -> Unit = {}) {
    val hovered = remember { mutableStateOf(false) }
    Row(
        modifier = Modifier
            .onMouseEnter { hovered.value = true }
            .onMouseLeave { hovered.value = false }
            .backgroundColor(if (hovered.value) Colors.WhiteSmoke else Colors.Transparent)
            .cursor(Cursor.Pointer)
            .padding(topBottom = 10.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(title)
        Gap(10.px)
        Checkbox(
            selected,
            modifier = Modifier
                .size(20.px)
                .onClick { onSelection() }
                .cursor(Cursor.Pointer)
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.SilkTheme
import org.jetbrains.compose.web.css.CSSColorValue

@Composable
fun TextButton(
    text: String,
    backgroundColor: CSSColorValue = SilkTheme.palette.button.default,
    hoverColor: CSSColorValue = Colors.WhiteSmoke,
    modifier: Modifier = Modifier
) {
    val bgColor = remember { mutableStateOf(backgroundColor) }
    Box(
        modifier
            .onMouseEnter {
                bgColor.value = hoverColor
            }
            .onMouseLeave {
                bgColor.value = backgroundColor
            }
            .backgroundColor(bgColor.value)
            .cursor(Cursor.Pointer),
        contentAlignment = Alignment.Center
    ) {
        SpanText(text)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.LoginService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.rotate
import com.varabyte.kobweb.silk.components.animation.Keyframes
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.deg

val Rotate360 by Keyframes {
    from { Modifier.rotate(0.deg) }
    to { Modifier.rotate(360.deg) }
}

@Composable
fun VerifyAuth(content: @Composable () -> Unit) {
    val renderPage = remember { mutableStateOf(false) }
    LaunchedEffect(Unit) {
        if (window.location.pathname.startsWith("/login")) {
            renderPage.value = true
            return@LaunchedEffect
        }
        if (!LoginService.instance.isLoggedIn()) {
            delay(5_000)
            window.location.assign("/login")
        } else {
            renderPage.value = true
            catch {
                RestClientFactory.authApi.checkUserSubscription()
            }
        }
    }
    if (renderPage.value) {
        content()
    }
}

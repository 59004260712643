package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramToolTaskDto
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch

class InstagramUsernamesScraperTaskSubmitService : InstagramFollowTaskSubmitService() {
    companion object {
        val instance = InstagramUsernamesScraperTaskSubmitService()
    }

    var scraperType: InstagramTool? = null
    var maxScraperLimit: Int? = null

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }
        catch {
            val result = when (scraperType) {
                InstagramTool.IG_USER_FOLLOWERS_SCRAPER -> submitUserFollowersScraper()
                InstagramTool.IG_USER_FOLLOWINGS_SCRAPER -> submitUserFollowingsScraper()
                InstagramTool.IG_USER_CONTACTS_SCRAPER -> submitUserContactsScraper()
                InstagramTool.IG_LOCATION_USERS_SCRAPER -> submitLocationUsersScraper()
                InstagramTool.IG_HASHTAG_USERS_SCRAPER -> submitHashTagUsersScraper()
                else -> throw IllegalStateException("Unknown scraper type found: ${scraperType?.name}")
            }
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
                InstagramUsernameInputService.instance.usernames.clear()
                InstagramLocationInputService.instance.locations.clear()
                InstagramHashTagInputService.instance.hashTags.clear()
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }

    private suspend fun submitUserFollowersScraper(): Boolean {
        usernameListChecker()
        return RestClientFactory.instagramApi.createUserFollowersScraperBot(
            agentId,
            createTasks()
        )
    }

    private suspend fun submitUserFollowingsScraper(): Boolean {
        usernameListChecker()
        return RestClientFactory
            .instagramApi
            .createUserFollowingsScraperBot(agentId, createTasks())
    }

    private suspend fun submitUserContactsScraper(): Boolean {
        usernameListChecker()
        return RestClientFactory
            .instagramApi
            .createUserContactsScraperBot(agentId, createTasks())
    }

    private suspend fun submitLocationUsersScraper(): Boolean {
        if (InstagramLocationInputService.instance.locations.isEmpty()) {
            throw IllegalStateException("Location list is empty")
        }
        return RestClientFactory
            .instagramApi
            .createLocationUsersScraperBot(
                agentId,
                createTasks(InstagramLocationInputService.instance.locations),
                maxScraperLimit
            )
    }

    private suspend fun submitHashTagUsersScraper(): Boolean {
        if (InstagramHashTagInputService.instance.hashTags.isEmpty()) {
            throw IllegalStateException("#Tag list is empty")
        }
        return RestClientFactory
            .instagramApi
            .createHashTagUsersScraperBot(
                agentId,
                createTasks(InstagramHashTagInputService.instance.hashTags),
                maxScraperLimit
            )
    }

    private fun createTasks(usernames: List<String> = InstagramUsernameInputService.instance.usernames): List<InstagramToolTaskDto> {
        val tasks = arrayListOf<InstagramToolTaskDto>()
        usernames.forEach { username ->
            appPackageNames.forEach { packageName ->
                tasks.add(
                    InstagramToolTaskDto(
                        username,
                        packageName,
                        strategy
                    )
                )
            }
        }
        return tasks
    }

    private fun usernameListChecker() {
        if (InstagramUsernameInputService.instance.usernames.isEmpty()) {
            throw IllegalStateException("Username list is empty")
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramMessageToFollowersToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramToolTaskDto
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.SpintaxParser

class InstagramMessageTaskSubmitService : InstagramFollowTaskSubmitService() {
    companion object {
        val instance = InstagramMessageTaskSubmitService()
    }

    var spintaxMessage: String = ""
    var messageToAllFollowers = false

    val spintaxParser = SpintaxParser()

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }

        if (spintaxMessage.isEmpty()) {
            StaticToastMessageService.showErrorToast("Spintax is empty")
            return
        }

        catch {
            val result = if (messageToAllFollowers) {
                submitMessageToAllFollowerTask()
            } else {
                submitMessageTasks()
            }
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
                InstagramUsernameInputService.instance.usernames.clear()
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }

    private suspend fun submitMessageToAllFollowerTask(): Boolean {
        val task = InstagramMessageToFollowersToolDto(strategy, appPackageNames, spintaxMessage)
        return RestClientFactory.instagramApi.createMessageToAllFollowersBot(agentId, task)
    }

    private suspend fun submitMessageTasks(): Boolean {
        if (InstagramUsernameInputService.instance.usernames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No usernames has been given")
            throw IllegalStateException("Username list is empty")
        }
        val tasks = arrayListOf<InstagramToolTaskDto>()
        InstagramUsernameInputService.instance.usernames.forEach { username ->
            appPackageNames.forEach { packageName ->
                tasks.add(
                    InstagramToolTaskDto(
                        username,
                        packageName,
                        strategy,
                        message = spintaxParser.parse(spintaxMessage)
                    )
                )
            }
        }
        return RestClientFactory.instagramApi.createMessageBot(agentId, tasks)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramAppsSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramHashTagInput
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramScraperMaxLimitInput
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ShowDataButton
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ShowUsernamesDialog
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TaskSubmitButton
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramHashTagInputService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramUsernamesScraperTaskSubmitService
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramHashTagUsersScraperCreateTaskLayout(agentId: String) {
    val showUsernameDialog = remember { mutableStateOf(false) }
    LaunchedEffect(agentId) {
        InstagramUsernamesScraperTaskSubmitService.instance.scraperType =
            InstagramTool.IG_HASHTAG_USERS_SCRAPER
        InstagramUsernamesScraperTaskSubmitService.instance.agentId = agentId
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        InstagramAppsSelector(agentId, onSelected = {
            InstagramUsernamesScraperTaskSubmitService.instance.appPackageNames = it
        })
        Gap(20.px)
        InstagramScraperMaxLimitInput(onChange = {
            InstagramUsernamesScraperTaskSubmitService.instance.maxScraperLimit = it
        })
        Gap(20.px)
        InstagramHashTagInput()
        Gap(20.px)
        Row(modifier = Modifier.fillMaxWidth()) {
            ShowDataButton(
                "Show #Tags (${InstagramHashTagInputService.instance.hashTags.size})",
                onClick = {
                    showUsernameDialog.value = true
                }
            )
            Gap(10.px)
            TaskSubmitButton(onClick = {
                GlobalScope.launch {
                    InstagramUsernamesScraperTaskSubmitService.instance.onSubmit()
                }
            })
        }
    }
    if (showUsernameDialog.value) {
        ShowUsernamesDialog(
            "Hash Tags",
            InstagramHashTagInputService.instance.hashTags,
            onDelete = { index ->
                InstagramHashTagInputService.instance.hashTags.removeAt(index)
            },
            deleteAll = {
                InstagramHashTagInputService.instance.hashTags.clear()
            },
            hideDialog = {
                showUsernameDialog.value = false
            }
        )
    }
}

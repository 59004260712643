package com.jamshedalamqaderi.socialdroid.webapp.domain.models

import kotlinx.serialization.Serializable

@Serializable
sealed class Gesture {
    @Serializable
    data class Tap(val x: Int, val y: Int) : Gesture()

    @Serializable
    data class LongTap(val longX: Int, val longY: Int) : Gesture()

    @Serializable
    data class Swipe(
        val x1: Float,
        val y1: Float,
        val x2: Float,
        val y2: Float,
        val duration: Long
    ) : Gesture()

    @Serializable
    data class NavigationKey(val name: String) : Gesture()

    @Serializable
    data class InputText(val value: String) : Gesture()
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun LinearProgressIndicator(
    modifier: Modifier = Modifier.fillMaxWidth(),
    barColor: CSSColorValue,
    progress: Double
) {
    Box(
        modifier = modifier
            .borderRadius(5.px)
            .overflow(Overflow.Hidden)
            .backgroundColor(Colors.Blue.copy(alpha = 20))
    ) {
        Box(
            modifier = Modifier.backgroundColor(barColor)
                .fillMaxWidth((progress * 100).toInt().percent)
                .fillMaxHeight()
        ) {}
    }
}

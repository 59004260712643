package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil
import com.jamshedalamqaderi.socialdroid.webapp.pages.TextInputField
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaPencil
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun AppNameEdit(
    app: DashboardAppModel,
    shouldRefresh: () -> Unit = {}
) {
    val updatedAppName = remember { mutableStateOf("") }
    val showAppNameUpdaterDialog = remember { mutableStateOf(false) }
    FaPencil(
        modifier = Modifier
            .padding(5.px)
            .color(Colors.Blue.copy(alpha = 70))
            .cursor(Cursor.Pointer)
            .onClick {
                showAppNameUpdaterDialog.value = true
            }
    )
    if (showAppNameUpdaterDialog.value) {
        Dialog(
            onClose = {
                showAppNameUpdaterDialog.value = false
            },
            onSubmit = {
                GlobalScope.launch {
                    ExceptionUtil.catch {
                        if (updatedAppName.value.isNotEmpty()) {
                            if (RestClientFactory.deviceAppApi.updateAppName(
                                    app.appId,
                                    updatedAppName.value
                                )
                            ) {
                                StaticToastMessageService.showSuccessToast("Updated successfully")
                                shouldRefresh()
                            } else {
                                StaticToastMessageService.showErrorToast("App name update failed")
                            }
                        } else {
                            StaticToastMessageService.showErrorToast("App name is empty")
                        }
                    }
                }
                showAppNameUpdaterDialog.value = false
            },
            submitButtonText = "Update"
        ) {
            SpanText(
                "Update app name",
                modifier = Modifier
                    .fontSize(16.pt)
                    .fontWeight(FontWeight.Bold)
            )
            Gap(20.px)
            TextInputField(
                InputType.Text,
                "App name",
                app.appName,
                modifier = Modifier.fillMaxWidth(),
                onValueChanged = {
                    updatedAppName.value = it
                }
            )
        }
    }
}

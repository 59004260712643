package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class TaskDataModel(
    val id: Long? = null,
    val taskId: Long,
    val dataPayload: String
)

package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.exceptions.ApiException
import com.jamshedalamqaderi.socialdroid.webapp.data.models.AuthLoginModel
import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType

class AuthApi(private val httpClient: HttpClient) : BaseApi("auth/") {

    suspend fun login(username: String, password: String, rememberMe: Boolean): String? {
        val response = httpClient
            .post(endpoint("login")) {
                contentType(ContentType.Application.Json)
                setBody(AuthLoginModel(username, password, rememberMe))
            }
        if (response.status == HttpStatusCode.OK) {
            return response.body<ResponseResult<String>>().data
        } else {
            throw ApiException(response.bodyAsText())
        }
    }

    suspend fun deviceAuth(username: String, password: String): String? {
        val response = httpClient
            .post(endpoint("device-auth")) {
                contentType(ContentType.Application.Json)
                setBody(AuthLoginModel(username, password, false))
            }
        if (response.status == HttpStatusCode.OK) {
            return response.body<ResponseResult<String>>().data
        } else {
            throw ApiException(response.bodyAsText())
        }
    }

    suspend fun checkUserSubscription(): Boolean {
        val response = httpClient
            .get(endpoint("check_user_subscription"))
        if (response.status == HttpStatusCode.OK) {
            return response.body<ResponseResult<Boolean>>().data ?: false
        } else {
            throw ApiException(response.bodyAsText())
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.StaticToastMessage
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.StaticToastMessageServiceImpl
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.bottom
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.right
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.defer.deferRender
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun StaticToastMessage() {
    val service = remember { StaticToastMessageServiceImpl.instance }
    val toasts = remember { mutableStateListOf<StaticToastMessage>() }

    LaunchedEffect(service.toastState.value, toasts) {
        if (service.toastState.value != StaticToastMessage.None) {
            toasts.add(service.toastState.value)
            service.toastState.value = StaticToastMessage.None
        }
    }

    deferRender {
        Column(
            modifier = Modifier
                .position(Position.Fixed)
                .bottom(20.px).right(20.px)
                .rowGap(5.px),
            horizontalAlignment = Alignment.End
        ) {
            toasts
                .forEachIndexed { index, staticToastMessage ->
                    ShowSingleToastMessage(staticToastMessage, onFinished = {
                        toasts[index] = StaticToastMessage.None
                        if (toasts.size - 1 == index) {
                            toasts.clear()
                        }
                    })
                }
        }
    }
}

@Composable
fun ShowSingleToastMessage(toastState: StaticToastMessage, onFinished: () -> Unit = {}) {
    val hide = remember { mutableStateOf(false) }
    if (hide.value) return
    LaunchedEffect(Unit) {
        if (toastState != StaticToastMessage.None) {
            delay(3_000)
            hide.value = true
            onFinished()
        }
    }
    val styleModifier = when (toastState) {
        is StaticToastMessage.Success -> Modifier.backgroundColor(Colors.LightGreen)
        is StaticToastMessage.Error -> Modifier.backgroundColor(Colors.Red).color(Colors.WhiteSmoke)
        StaticToastMessage.None -> Modifier
    }
    Box(
        modifier = Modifier
            .zIndex(100000)
            .padding(10.px)
            .minWidth(250.px)
            .maxWidth(400.px)
            .fontSize(12.pt)
            .borderRadius(5.px)
            .then(styleModifier)
    ) {
        Row(
            modifier = Modifier.fillMaxSize(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            SpanText(
                toastState.message,
                modifier = Modifier.flexWrap(FlexWrap.Wrap)
            )
            FaXmark(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .backgroundColor(Colors.White)
                    .color(Colors.Red)
                    .borderRadius(5.px)
                    .margin(left = 10.px)
                    .padding(5.px)
                    .onClick {
                        hide.value = true
                        onFinished()
                    }
            )
        }
    }
}

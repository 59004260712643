package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.TaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch

open class InstagramOwnFollowersTaskSubmitService : TaskSubmitService {
    companion object {
        val instance = InstagramOwnFollowersTaskSubmitService()
    }

    var agentId: String = ""
    var appPackageNames = listOf<String>()

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }
        catch {
            val result = appPackageNames.map {
                RestClientFactory
                    .instagramApi
                    .createOwnFollowersScraperBot(
                        agentId,
                        it
                    )
            }.reduce { acc, b -> acc && b }
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }
}

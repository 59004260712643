package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.BuildKonfig
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceUserModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DashboardTaskStatistics
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceAppService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.FaMobileScreen
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

val ButtonHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.WhiteSmoke)
    }

    cssRule(":hover") {
        Modifier.backgroundColor(Colors.Grey.copy(alpha = 50))
    }
}

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun DashboardAgent(agent: DeviceUserModel) {
    val context = rememberPageContext()
    val expanded = remember { mutableStateOf(false) }
    val deviceAppService = remember { DeviceAppService() }
    val showScreenMirrorDialog = remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        deviceAppService.refresh(mapOf("agentId" to agent.deviceId))
        while (this.isActive) {
            delay(10_000)
            kotlin.runCatching {
                deviceAppService.refresh(mapOf("agentId" to agent.deviceId))
            }
        }
    }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(Colors.White)
            .borderRadius(10.px)
            .margin(top = 10.px)
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 10.px, topBottom = 15.px)
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(
                    modifier = Modifier
                        .backgroundColor(Colors.Blue.copy(alpha = 10))
                        .size(32.px)
                        .padding(10.px)
                        .alignContent(AlignContent.Center)
                        .borderRadius(3.px)
                        .cursor(Cursor.Pointer)
                        .onClick {
                            expanded.value = !expanded.value
                        },
                    contentAlignment = Alignment.Center
                ) {
                    FaIcon(
                        if (expanded.value) "angle-down" else "angle-right",
                        size = IconSize.X1,
                        style = IconCategory.SOLID,
                        modifier = Modifier
                            .color(Colors.Blue.copy(alpha = 40))
                    )
                }
                Gap(10.px)
                Column(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        SpanText(
                            agent.deviceName ?: "",
                            modifier = Modifier.onClick {
                                context.router.navigateTo("/instagram-tools/${agent.deviceId}")
                            }
                        )
                        Gap(10.px)
                        AgentNameEdit(
                            agent.deviceId ?: "",
                            agent.deviceName ?: "",
                            shouldRefresh = {
                                GlobalScope.launch {
                                    catch(showToast = false) {
                                        DeviceService.instance.refresh(mapOf())
                                    }
                                }
                            }
                        )
                    }
                    SpanText(
                        "${agent.deviceId} (${agent.user})",
                        modifier = Modifier
                            .fontSize(10.pt)
                            .color(Colors.Blue.copy(alpha = 60))
                            .onClick {
                                context.router.navigateTo("/instagram-tools/${agent.deviceId}")
                            }
                    )
                }
            }
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (BuildKonfig.version != (agent.agentVersion ?: "")) {
                    TextButton(
                        "Update Agent",
                        backgroundColor = Colors.Green.copy(alpha = 50),
                        hoverColor = Colors.Green.copy(alpha = 20),
                        modifier = Modifier
                            .padding(topBottom = 5.px, leftRight = 10.px)
                            .borderRadius(5.px)
                            .onClick {
                                context.router.navigateTo("/download-agent")
                            }
                    )
                }
                Box(Modifier.width(50.px))
                FaMobileScreen(
                    size = IconSize.XXL,
                    modifier = Modifier
                        .color(Colors.Gray)
                        .cursor(Cursor.Pointer)
                        .onClick {
                            showScreenMirrorDialog.value = true
                        }
                )
                Gap(10.px)
                AgentStatusChip(
                    agent.isApproved,
                    onlineText = "Approved",
                    offlineText = "Approve",
                    modifier = Modifier
                        .width(100.px)
                        .onClick {
                            GlobalScope.launch {
                                catch {
                                    if (RestClientFactory.agentUserApi.approveDevice(
                                            agent.deviceId!!,
                                            !agent.isApproved
                                        )
                                    ) {
                                        StaticToastMessageService.showSuccessToast(if (agent.isApproved) "Disapproved" else "Approved")
                                    } else {
                                        StaticToastMessageService.showErrorToast("Failed for unknown reason")
                                    }
                                    deviceAppService.refresh(mapOf("agentId" to agent.deviceId))
                                }
                            }
                        }
                )
                Gap(10.px)
                AgentStatusChip(agent.isOnline, modifier = Modifier.width(80.px))
            }
        }
        if (!expanded.value) return@Column
        Gap(10.px)
        AppsTable(
            context,
            agent.deviceId!!,
            deviceAppService.apps.value,
            shouldRefresh = {
                GlobalScope.launch {
                    deviceAppService.refresh(mapOf("agentId" to agent.deviceId))
                }
            },
            shouldRefreshTaskCount = {
                GlobalScope.launch {
                    DashboardTaskStatistics.instance.refresh(mapOf())
                }
            }
        )
        Gap(10.px)
    }
    if (showScreenMirrorDialog.value) {
        ScreenMirrorDialog(
            agent.deviceId ?: "",
            onClose = {
                showScreenMirrorDialog.value = false
            }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class InstagramSettingCopyService {

    companion object {
        val instance = InstagramSettingCopyService()
    }

    suspend fun copySetting(agentId: String, tool: InstagramTool) {
        catch {
            val service = InstagramActionToolSettingService()
            service.refresh(mapOf("agentId" to agentId, "instagramTool" to tool.name))
            window.localStorage.setItem("IG_SETTING", Json.encodeToString(service.setting.value))
            StaticToastMessageService.showSuccessToast("Copied")
        }
    }

    suspend fun pasteSetting(agentId: String, tool: InstagramTool) {
        catch {
            val payload = window.localStorage.getItem("IG_SETTING")
            if (payload == null) {
                StaticToastMessageService.showErrorToast("Couldn't found ${tool.toolName} in clipboard")
                return@catch
            }
            val service = InstagramSettingSubmitService(agentId, tool)
            service.loadFromSetting(Json.decodeFromString(payload))
            service.onSubmit()
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.SpintaxParser
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun SpintaxMessageInput(onChange: (String) -> Unit = {}) {
    val spintax = remember { mutableStateOf("") }
    val showValidateDialog = remember { mutableStateOf(false) }
    val generatedSpintax = remember { mutableStateOf("") }
    val spintaxParser = remember { SpintaxParser() }
    TextField(
        spintax.value,
        "Spintax Message",
        "Write message based on Spintax (eg: {Hi|Hello}, Socialdroid!)",
        onValueChange = {
            spintax.value = it
            onChange(it)
        },
        option = {
            TextButton(
                "Validate Spintax",
                backgroundColor = Colors.Blue.copy(alpha = 50),
                hoverColor = Colors.Blue.copy(alpha = 30),
                modifier = Modifier
                    .fontSize(10.pt)
                    .padding(leftRight = 15.px, topBottom = 5.px)
                    .borderRadius(3.px)
                    .onClick {
                        showValidateDialog.value = true
                        generatedSpintax.value = spintaxParser.parse(spintax.value)
                    }
            )
        },
        modifier = Modifier.height(150.px)
    )
    if (showValidateDialog.value) {
        Dialog(
            onClose = {
                showValidateDialog.value = false
            },
            onSubmit = {
                generatedSpintax.value = spintaxParser.parse(spintax.value)
            },
            submitButtonText = "Spin"
        ) {
            Column {
                SpanText(
                    "Spintax Validation",
                    modifier = Modifier
                        .fontSize(16.pt)
                        .fontWeight(FontWeight.Bold)
                )
                Gap(20.px)
                SpanText(generatedSpintax.value)
            }
        }
    }
}

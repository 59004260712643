package com.jamshedalamqaderi.socialdroid.webapp.domain.models

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserToken(
    val appLimit: Int,
    val subscriptionType: String,
    val roles: List<String>,
    @SerialName("iss")
    val issuer: String,
    @SerialName("exp")
    val expireAt: LocalDateTime,
    val deviceLimit: Int,
    val username: String
)

package com.jamshedalamqaderi.socialdroid.webapp.data.enums

enum class IgAppState {
    SLEEPING, WORKING;

    companion object {
        fun valueOrNull(value: String?): IgAppState? {
            return IgAppState.values().find { it.name == value }
        }
    }
}

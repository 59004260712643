package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import kotlinx.serialization.Serializable

@Serializable
data class TaskModel(
    val taskId: Long? = null,
    val taskTag: String,
    val taskMetadata: String,
    val deviceId: String,
    val taskState: TaskState
)

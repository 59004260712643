package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.IgAppState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardTask
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceAppModel
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramActionToolSetting
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramCommonBotDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramMessageToFollowersToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramPostingToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramToolTaskDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramUnfollowAllFollowingsToolDto
import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

class InstagramApi(private val httpClient: HttpClient) : BaseApi("instagram_bot/") {

    suspend fun allApps(deviceId: String): List<DeviceAppModel> {
        return httpClient
            .get(endpoint("ig_clone_list/$deviceId"))
            .body<ResponseResult<List<DeviceAppModel>>>()
            .data ?: emptyList()
    }

    suspend fun updateAppState(appId: Long, state: IgAppState): Boolean {
        return httpClient
            .get(endpoint("change_app_state/$appId/${state.name}"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun createFollowBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "follow",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createUnfollowBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "unfollow",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createMessageBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "message",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createPostingBot(
        postingModel: InstagramPostingToolDto
    ): Boolean {
        return createInstagramBot(
            "posting",
            postingModel
        )
    }

    suspend fun createUserFollowersScraperBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "scraper/${InstagramTool.IG_USER_FOLLOWERS_SCRAPER.name}",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createUserFollowingsScraperBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "scraper/${InstagramTool.IG_USER_FOLLOWINGS_SCRAPER.name}",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createUserContactsScraperBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "scraper/${InstagramTool.IG_USER_CONTACTS_SCRAPER.name}",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createLocationUsersScraperBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "scraper/${InstagramTool.IG_LOCATION_USERS_SCRAPER.name}",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createHashTagUsersScraperBot(
        deviceId: String,
        data: List<InstagramToolTaskDto>,
        scraperDataLimit: Int? = null
    ): Boolean {
        return createInstagramBot(
            "scraper/${InstagramTool.IG_HASHTAG_USERS_SCRAPER.name}",
            InstagramCommonBotDto(
                deviceId,
                data,
                scraperDataLimit
            )
        )
    }

    suspend fun createOwnFollowersScraperBot(
        deviceId: String,
        packageName: String
    ): Boolean {
        return httpClient
            .get(endpoint("ig_followers_scraper/$deviceId/$packageName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun createOwnFollowingsScraperBot(
        deviceId: String,
        packageName: String
    ): Boolean {
        return httpClient
            .get(endpoint("ig_following_scraper/$deviceId/$packageName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun createUnfollowAllFollowingsBot(
        deviceId: String,
        model: InstagramUnfollowAllFollowingsToolDto
    ): Boolean {
        return httpClient
            .post(endpoint("unfollow_all_followings/$deviceId")) {
                contentType(ContentType.Application.Json)
                setBody(model)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun createMessageToAllFollowersBot(
        deviceId: String,
        model: InstagramMessageToFollowersToolDto
    ): Boolean {
        return httpClient
            .post(endpoint("message_to_followers/$deviceId")) {
                contentType(ContentType.Application.Json)
                setBody(model)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun getDashboardTasks(
        deviceId: String,
        tool: InstagramTool,
        page: Int,
        limit: Int,
        packageName: String? = null
    ): List<DashboardTask> {
        return httpClient
            .get(endpoint("dashboard_tasks/$deviceId/$tool/$page/$limit?packageName=$packageName"))
            .body<ResponseResult<List<DashboardTask>>>()
            .data ?: emptyList()
    }

    suspend fun getActionToolSetting(
        agentId: String,
        toolType: InstagramTool
    ): InstagramActionToolSetting? {
        return httpClient
            .get(endpoint("ig_bot_setting/$agentId/${toolType.name}"))
            .body<ResponseResult<InstagramActionToolSetting>>()
            .data
    }

    suspend fun updateActionToolSetting(
        agentId: String,
        toolType: InstagramTool,
        data: InstagramActionToolSetting
    ): Boolean {
        return httpClient
            .post(endpoint("ig_bot_setting/$agentId/${toolType.name}")) {
                contentType(ContentType.Application.Json)
                setBody(data)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun clearFollowBackRatio(agentId: String, packageName: String): Boolean {
        return httpClient
            .get(endpoint("clear_fbr/$agentId/$packageName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun calculateFollowBackRatio(agentId: String, packageName: String): Boolean {
        return httpClient
            .get(endpoint("calc_fbr/$agentId/$packageName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun getActionBotStatus(
        agentId: String,
        instagramTool: InstagramTool
    ): IgAppState {
        val result = httpClient
            .get(endpoint("ig_bot_status/$agentId/${instagramTool.name}"))
            .body<ResponseResult<String>>()
            .data
        return IgAppState.valueOrNull(
            result
        ) ?: IgAppState.SLEEPING
    }

    private suspend inline fun <reified T> createInstagramBot(
        endpoint: String,
        model: T
    ): Boolean {
        return httpClient
            .post(endpoint(endpoint)) {
                contentType(ContentType.Application.Json)
                setBody(model)
            }.body<ResponseResult<Boolean>>()
            .data ?: false
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class WorkdaysModel(
    val saturdayEnabled: Boolean = true,
    val sundayEnabled: Boolean = true,
    val mondayEnabled: Boolean = true,
    val tuesdayEnabled: Boolean = true,
    val wednesdayEnabled: Boolean = true,
    val thursdayEnabled: Boolean = true,
    val fridayEnabled: Boolean = true
)

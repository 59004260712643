package com.jamshedalamqaderi.socialdroid.webapp.domain.utils

class SpintaxParser {

    fun parse(input: String): String {
        var startIndex = 0
        var endIndex = 0
        var output = ""
        while (startIndex < input.length) {
            endIndex = input.indexOf("{", startIndex)

            if (endIndex == -1) {
                output += input.substring(startIndex)
                break
            } else {
                output += input.substring(startIndex, endIndex)
                startIndex = endIndex + 1
                endIndex = input.indexOf("}", startIndex)

                if (endIndex == -1) {
                    output += input.substring(startIndex - 1)
                    break
                } else {
                    val choices = input.substring(startIndex, endIndex).split("|")
                    output += choices.random()
                    startIndex = endIndex + 1
                }
            }
        }

        return output
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders

class ImageApi(private val httpClient: HttpClient, private val baseUrl: String) :
    BaseApi("images/") {

    suspend fun uploadImage(filename: String, mimeType: String, image: ByteArray): String? {
        return httpClient.post(endpoint("")) {
            setBody(
                MultiPartFormDataContent(
                    formData {
                        append(
                            "image",
                            image,
                            Headers.build {
                                append(HttpHeaders.ContentType, mimeType)
                                append(HttpHeaders.ContentDisposition, "filename=\"$filename\"")
                            }
                        )
                    }
                )
            )
        }.body<ResponseResult<String>>().data
    }

    suspend fun deleteImage(imageName: String): Boolean {
        return httpClient.get("delete/$imageName")
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    fun getImageUrl(imageName: String): String {
        return "$baseUrl${endpoint(imageName)}"
    }
}

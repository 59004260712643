package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramLocationInputService

@Composable
fun InstagramLocationsInput() {
    val text = remember { mutableStateOf("") }
    TextField(
        text.value,
        "Input Locations",
        "Press 'Enter' to save (eg: `New York, USA`)",
        onValueChange = { newValue ->
            if (InstagramLocationInputService.instance.onLocationInput(newValue)) {
                text.value = ""
            } else {
                text.value = newValue
            }
        }
    )
}

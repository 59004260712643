package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import com.jamshedalamqaderi.socialdroid.webapp.data.models.TaskModel
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

class TaskApi(private val httpClient: HttpClient) : BaseApi("task/") {

    suspend fun countTasksByDevice(deviceId: String, taskState: TaskState): Int {
        return httpClient
            .get(endpoint("count_tasks/$deviceId/$taskState"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun countTasks(taskState: TaskState): Int {
        return httpClient
            .get(endpoint("count_tasks/$taskState"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun countTasks(deviceId: String, taskTag: String, taskState: TaskState): Int {
        return httpClient
            .get(endpoint("count_tasks/$deviceId/$taskTag/$taskState"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun countTasks(deviceId: String, taskTag: String): Int {
        return httpClient
            .get(endpoint("count_all_tasks_by_tasktag/$deviceId/$taskTag/"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun countAll(): Int {
        return httpClient
            .get(endpoint("countAll"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun clearAllTask(deviceId: String, packageName: String): Boolean {
        return httpClient
            .get(endpoint("clear_task_by_package/$deviceId/$packageName"))
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun deleteTasks(tasks: List<Long>): Boolean {
        return httpClient
            .post(endpoint("deletes")) {
                contentType(ContentType.Application.Json)
                setBody(tasks)
            }
            .body<ResponseResult<Boolean>>()
            .data ?: false
    }

    suspend fun taskIdList(
        agentId: String,
        tool: InstagramTool,
        page: Int,
        limit: Int
    ): List<Long> {
        return httpClient
            .get(endpoint("task_id_list/$agentId/${tool.name}/$page/$limit"))
            .body<ResponseResult<List<Long>>>()
            .data ?: emptyList()
    }

    suspend fun taskIdList(
        agentId: String,
        tool: InstagramTool,
        packageName: String,
        page: Int,
        limit: Int
    ): List<Long> {
        return httpClient
            .get(endpoint("task_id_list/$agentId/${tool.name}/$packageName/$page/$limit"))
            .body<ResponseResult<List<Long>>>()
            .data ?: emptyList()
    }

    suspend fun taskById(id: Long): TaskModel? {
        return httpClient
            .get(endpoint("$id"))
            .body<ResponseResult<TaskModel>>()
            .data
    }
}

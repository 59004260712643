package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.size
import org.jetbrains.compose.web.css.CSSNumeric

@Composable
fun Gap(value: CSSNumeric) {
    Box(modifier = Modifier.size(value))
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember

@Composable
fun InstagramScraperMaxLimitInput(onChange: (Int?) -> Unit = {}) {
    val text = remember { mutableStateOf("") }
    TextField(
        text.value,
        "Input Maximum Limit",
        "Only numbers (eg: `500`)",
        onValueChange = { newValue ->
            try {
                if (newValue.isEmpty()) {
                    text.value = newValue
                    onChange(null)
                } else {
                    val maxLimit = newValue.toInt()
                    text.value = newValue
                    onChange(maxLimit)
                }
            } catch (_: Exception) {
            }
        }
    )
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramAppService
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun InstagramAppsSelector(agentId: String, onSelected: (List<String>) -> Unit = {}) {
    val instagramAppService = remember { InstagramAppService() }

    LaunchedEffect(agentId) {
        instagramAppService.refresh(mapOf("agentId" to agentId))
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth(),
//            horizontalArrangement = Arrangement.SpaceBetween
            verticalAlignment = Alignment.CenterVertically
        ) {
            SpanText(
                "Instagram Apps",
                modifier = Modifier.fontSize(18.pt).fontWeight(FontWeight.Bold)
            )
            Gap(20.px)
            TextButton(
                if (instagramAppService.isSelectedAll.value) "Deselect All" else "Select All",
                backgroundColor = Colors.Blue.copy(alpha = 50),
                hoverColor = Colors.Blue.copy(alpha = 30),
                modifier = Modifier
                    .fontSize(10.pt)
                    .padding(leftRight = 15.px, topBottom = 5.px)
                    .borderRadius(3.px)
                    .onClick {
                        instagramAppService.onSelectAll()
                        onSelected(instagramAppService.getSelectedAppsPackageNames())
                    }
            )
        }
        Gap(15.px)
        if (instagramAppService.apps.isEmpty()) {
            Row(
                modifier = Modifier.fillMaxWidth().height(100.px),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically
            ) {
                SpanText("App list is empty")
            }
            return@Column
        }
        Row(
            modifier = Modifier.flexWrap(FlexWrap.Wrap).rowGap(10.px).columnGap(10.px)
        ) {
            instagramAppService.apps.forEach { appModel ->
                AppItem(
                    selected = instagramAppService.selectedApps.contains(appModel.id),
                    title = appModel.appName,
                    subtitle = appModel.packageName,
                    onClick = {
                        instagramAppService.onAppSelection(appModel.id)
                        onSelected(instagramAppService.getSelectedAppsPackageNames())
                    }
                )
            }
        }
    }
}

@Composable
fun AppItem(selected: Boolean, title: String, subtitle: String, onClick: () -> Unit = {}) {
    Column(
        modifier = Modifier
            .width(180.px)
            .overflow(Overflow.Hidden)
            .backgroundColor(if (selected) Colors.Green.copy(alpha = 50) else Colors.White)
            .padding(10.px).borderRadius(10.px)
            .border(if (selected) 2.px else 0.px, LineStyle.Solid, Colors.Green.copy(alpha = 75))
            .cursor(Cursor.Pointer)
            .onClick {
                onClick()
            }
    ) {
        SpanText(
            title,
            modifier = Modifier.fontSize(10.pt).fontWeight(FontWeight.Bold)
        )
        SpanText(
            subtitle,
            modifier = Modifier.fontSize(10.pt).color(Colors.Black.copy(alpha = 75))
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import kotlinx.coroutines.delay

@Page("/mass-emailer-software")
@Composable
fun MassEmailerSoftware() {
    LaunchedEffect(Unit) {
        delay(2_000)
        window.open(
            "https://gmonster.co/",
            "_blank"
        )
    }
    MainMenuLayout(SocialdroidMainMenuOptions.MassEmailSoftware) {
        SpanText("Redirecting to https://gmonster.co")
    }
}

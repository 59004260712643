package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.models.ResponseResult
import com.jamshedalamqaderi.socialdroid.webapp.data.models.TaskDataModel
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get

class TaskDataApi(private val httpClient: HttpClient) : BaseApi("task_data/") {

    suspend fun countByTaskId(taskId: Long): Int {
        return httpClient
            .get(endpoint("count_by_task_id/$taskId"))
            .body<ResponseResult<Int>>()
            .data ?: 0
    }

    suspend fun findByTaskId(taskId: Long, page: Int, limit: Int): List<TaskDataModel> {
        return httpClient
            .get(endpoint("find_by_task_id/$taskId/$page/$limit"))
            .body<ResponseResult<List<TaskDataModel>>>()
            .data ?: emptyList()
    }
}

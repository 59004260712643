package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class InstagramActionToolSetting(
    val batchSize: IntPair? = null,
    val waitInSec: IntPair? = null,
    val taskDelayInSec: IntPair? = null,
    val taskPerDay: IntPair? = null,
    val taskPerHour: IntPair? = null,
    val sleep: IntPair? = null,
    val manualSleep: Boolean? = null
)

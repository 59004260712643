package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TextButton
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import io.github.g0dkar.qrcode.QRCode
import kotlinx.browser.window
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Page("/download-agent")
@Composable
fun DownloadAgent() {
    val socialdroidAgentApkUrl = remember { mutableStateOf("") }
    val socialdroidAgentApkQr = remember { mutableStateOf("") }
    val agentHelperApkUrl = remember { mutableStateOf("") }
    val agentHelperApkQr = remember { mutableStateOf("") }
    LaunchedEffect(Unit) {
        socialdroidAgentApkUrl.value = window.location.origin + "/public/agent-release.apk"
        agentHelperApkUrl.value = window.location.origin + "/public/agenthelper-release.apk"
        socialdroidAgentApkQr.value = QRCode(socialdroidAgentApkUrl.value)
            .render()
            .toDataURL()
        agentHelperApkQr.value = QRCode(agentHelperApkUrl.value)
            .render()
            .toDataURL()
    }
    MainMenuLayout(SocialdroidMainMenuOptions.DownloadAgent) {
        DashboardBodyLayout("Download Agent") {
            Row(
                modifier = Modifier.fillMaxSize(),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically
            ) {
                QrCodeSection(
                    "Socialdroid Agent",
                    socialdroidAgentApkQr.value,
                    socialdroidAgentApkUrl.value,
                    "."
                )
                Gap(50.px)
                QrCodeSection(
                    "Agent Helper",
                    agentHelperApkQr.value,
                    agentHelperApkUrl.value,
                    "Note: Only required for contact scraping"
                )
            }
        }
    }
}

@Composable
fun QrCodeSection(title: String, qrUrl: String, fileUrl: String, note: String = "") {
    Column(
        modifier = Modifier.width(300.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SpanText(
            title,
            modifier = Modifier.fontSize(24.pt).color(Colors.Black)
        )
        Gap(50.px)
        Image(
            qrUrl,
            modifier = Modifier.boxShadow(
                4.px,
                4.px,
                8.px,
                2.px,
                Colors.Black.copy(alpha = 30)
            ).borderRadius(10.px).size(200.px)
        )
        Gap(30.px)
        SpanText(
            "OR",
            modifier = Modifier.fontSize(10.pt).color(Colors.Blue.copy(alpha = 30))
        )
        Gap(20.px)
        SpanText(
            note,
            modifier = Modifier.fontSize(10.pt).color(Colors.Gray)
        )
        Gap(20.px)
        TextButton(
            "Download",
            backgroundColor = Colors.Blue.copy(alpha = 75),
            hoverColor = Colors.Blue.copy(alpha = 70),
            modifier = Modifier.padding(10.px).borderRadius(5.px).color(Colors.WhiteSmoke)
                .boxShadow(
                    2.px,
                    2.px,
                    4.px,
                    2.px,
                    Colors.Black.copy(alpha = 30)
                )
                .onClick {
                    window.open(
                        fileUrl,
                        "_blank"
                    )
                }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import com.jamshedalamqaderi.socialdroid.webapp.data.models.TaskDataModel
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.InstagramContactDataModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.InstagramUsernameDataModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.Utils.fromJson
import kotlinx.browser.document
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

class CsvDownloadService {

    companion object {
        val instance = CsvDownloadService()
    }

    suspend fun retrieveContactData(agentId: String, packageName: String?) {
        catch {
            StaticToastMessageService.showSuccessToast("Downloading started. Please wait...")
            val totalContactTasks = RestClientFactory.taskApi.countTasks(
                agentId,
                InstagramTool.IG_USER_CONTACTS_SCRAPER.name,
                TaskState.Completed
            )
            val totalPage = if (totalContactTasks < 100) 1 else totalContactTasks / 100
            val taskIdList = mutableListOf<Long>()
            repeat(totalPage) { page ->
                val idList = if (packageName == null) {
                    RestClientFactory.taskApi.taskIdList(
                        agentId,
                        InstagramTool.IG_USER_CONTACTS_SCRAPER,
                        page,
                        100
                    )
                } else {
                    RestClientFactory.taskApi.taskIdList(
                        agentId,
                        InstagramTool.IG_USER_CONTACTS_SCRAPER,
                        packageName,
                        page,
                        100
                    )
                }
                taskIdList.addAll(idList)
            }
            val contactCsvLines = taskIdList.map { taskId ->
                val dataModels = RestClientFactory.taskDataApi.findByTaskId(taskId, 0, 10)
                val task = RestClientFactory.taskApi.taskById(taskId)
                val jsonElement = Json.parseToJsonElement(task?.taskMetadata ?: "")
                val username = jsonElement.jsonObject["username"]?.jsonPrimitive?.content ?: ""
                convertToContactLine(dataModels, username)
            }.joinToString("\n")
            downloadCsv(
                "Contacts_${agentId}${if (packageName == null) "" else "_$packageName"}",
                "Username,Call,Text,Email\n$contactCsvLines"
            )
        }
    }

    suspend fun retrieveData(taskId: Long, tool: InstagramTool, username: String) {
        StaticToastMessageService.showSuccessToast("Downloading started. Please wait...")
        val totalDataCount = RestClientFactory.taskDataApi.countByTaskId(taskId)
        val totalPage = if (totalDataCount < 100) 1 else totalDataCount / 100
        val usernames = mutableListOf<InstagramUsernameDataModel>()
        repeat(totalPage) { page ->
            val taskData = RestClientFactory.taskDataApi.findByTaskId(taskId, page, 100)
            usernames.addAll(taskData.mapNotNull { it.dataPayload.fromJson<InstagramUsernameDataModel>() })
        }
        val usernameLines = usernames.joinToString("\n") { it.username }
        downloadCsv(tool.toolName + "_" + username, usernameLines)
    }

    private fun downloadCsv(name: String, data: String) {
        val blob = Blob(arrayOf(data), options = BlobPropertyBag(type = "text/csv;charset=utf-8;"))
        val url = URL.createObjectURL(blob)
        val link = document.createElement("a") as HTMLAnchorElement
        link.setAttribute("href", url)
        link.setAttribute("download", "$name.csv")
        link.setAttribute("target", "_blank")
        document.body?.appendChild(link)
        link.click()
    }

    private fun convertToContactLine(dataModel: List<TaskDataModel>, username: String): String {
        var call = ""
        var text = ""
        var email = ""
        dataModel.mapNotNull { it.dataPayload.fromJson<InstagramContactDataModel>() }
            .forEach {
                if (call.isEmpty()) {
                    call = contactColumn("Call", "Request", it)
                }
                if (text.isEmpty()) {
                    text = contactColumn("Text", "Request", it)
                }
                if (email.isEmpty()) {
                    email = contactColumn("Email", "Request", it)
                }
            }
        return "$username,$call,$text,$email"
    }

    private fun contactColumn(
        titleCompare: String,
        valueCompare: String,
        contactDataModel: InstagramContactDataModel
    ): String {
        return if (contactDataModel.title.startsWith(
                titleCompare,
                ignoreCase = true
            ) && !contactDataModel.value.startsWith(valueCompare, ignoreCase = true)
        ) {
            contactDataModel.value
        } else {
            ""
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px

val ArrowHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.White)
    }
    hover {
        Modifier.backgroundColor(Colors.Red.copy(alpha = 50))
    }
}

@Composable
fun Pagination(currentPage: Int, totalPage: Int, onPageChange: (Int) -> Unit = {}) {
    val pageIndices = generatePageIndices(totalPage, currentPage)

    Row(modifier = Modifier.columnGap(5.px), verticalAlignment = Alignment.CenterVertically) {
        val commonModifier = Modifier
            .size(40.px)
            .borderRadius(5.px)
            .boxShadow(2.px, 2.px, 5.px, 2.px, Colors.Black.copy(alpha = 30))
            .cursor(Cursor.Pointer)
            .then(ArrowHoverStyle.toModifier())
        if (currentPage > 1) {
            Box(
                commonModifier
                    .onClick {
                        onPageChange(currentPage - 1)
                    },
                contentAlignment = Alignment.Center
            ) {
                FaArrowLeft()
            }
        }
        repeat(pageIndices.size) {
            val index = pageIndices[it]
            TextButton(
                "$index",
                backgroundColor = if (currentPage == index) Colors.Red.copy(alpha = 50) else Colors.White,
                hoverColor = Colors.Red.copy(alpha = 50),
                modifier = commonModifier.onClick {
                    onPageChange(index)
                }
            )
        }
        if (currentPage < totalPage) {
            Box(
                commonModifier.onClick {
                    onPageChange(currentPage + 1)
                },
                contentAlignment = Alignment.Center
            ) {
                FaArrowRight()
            }
        }
    }
}

fun generatePageIndices(totalPages: Int, currentIndex: Int): List<Int> {
    val pageIndices = mutableListOf<Int>()

    // Calculate the range of indices to display
    val start = maxOf(1, currentIndex - 2)
    val end = minOf(totalPages, currentIndex + 2)
    // Generate the list of indices
    for (i in start..end) {
        pageIndices.add(i)
    }
    return pageIndices
}

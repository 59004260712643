package com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces

import com.jamshedalamqaderi.socialdroid.webapp.domain.models.StaticToastMessage
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.StaticToastMessageServiceImpl

abstract class StaticToastMessageService {
    companion object : StaticToastMessageService() {

        override fun showToast(message: StaticToastMessage) {
            StaticToastMessageServiceImpl.instance.showToast(message)
        }
    }

    abstract fun showToast(message: StaticToastMessage)
    fun showSuccessToast(message: String) {
        showToast(StaticToastMessage.Success(message))
    }

    fun showErrorToast(message: String) {
        showToast(StaticToastMessage.Error(message))
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramActionToolSetting
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class InstagramActionToolSettingService : SingleResponsibilityService {
    val setting = mutableStateOf<InstagramActionToolSetting?>(null)

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            val agentId = params["agentId"] ?: return
            val instagramTool = InstagramTool.valueOrNull(params["instagramTool"]) ?: return
            setting.value =
                RestClientFactory.instagramApi.getActionToolSetting(agentId, instagramTool)
        }
    }
}

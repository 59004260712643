package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.px

@Composable
fun ShowDataButton(text: String, onClick: () -> Unit = {}) {
    TextButton(
        text,
        backgroundColor = Colors.Grey.copy(alpha = 75),
        hoverColor = Colors.Grey.copy(alpha = 50),
        modifier = Modifier
            .width(250.px)
            .padding(10.px)
            .borderRadius(5.px)
            .onClick {
                onClick()
            }
    )
}

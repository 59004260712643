package com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.textAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Td

@Composable
inline fun <reified T> TableData(
    modifier: Modifier = Modifier,
    crossinline content: @Composable (Int, T) -> Unit
): @Composable (Int, T) -> Unit {
    return { index, data ->
        Td(
            attrs = Modifier
                .padding(10.px, 5.px, 10.px, 5.px)
                .textAlign(TextAlign.Center)
                .then(modifier)
                .toAttrs()
        ) {
            content(index, data)
        }
    }
}

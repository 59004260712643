package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.SingleResponsibilityService

class DashboardTaskStatistics : SingleResponsibilityService {
    val totalTasks = mutableStateOf(0)
    val completedTasks = mutableStateOf(0)
    val queuedTasks = mutableStateOf(0)
    val failedTasks = mutableStateOf(0)

    override suspend fun refresh(params: Map<String, String?>) {
        kotlin.runCatching {
            totalTasks.value = RestClientFactory.taskApi.countAll()
            completedTasks.value = RestClientFactory.taskApi.countTasks(TaskState.Completed)
            queuedTasks.value = RestClientFactory.taskApi.countTasks(TaskState.Queued)
            failedTasks.value = RestClientFactory.taskApi.countTasks(TaskState.Failed)
        }
    }

    companion object {
        val instance = DashboardTaskStatistics()
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun LabeledEditField(
    value: String,
    placeholder: String,
    label: String,
    onChange: (String) -> Unit = {}
) {
    Column {
        SpanText(label, modifier = Modifier.fontSize(8.pt).fontWeight(FontWeight.Bold))
        TextInput(
            value,
            attrs = TextFieldStyle
                .toModifier()
                .outline(0.px, LineStyle.None)
                .width(80.px)
                .height(30.px)
                .borderRadius(5.px)
                .toAttrs {
                    placeholder(placeholder)
                    onInput {
                        onChange(it.value)
                    }
                }
        )
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.DeviceAppState
import kotlinx.serialization.Serializable

@Serializable
data class DeviceAppModel(
    val id: Long,
    val deviceId: String,
    val appName: String,
    val packageName: String,
    val appState: DeviceAppState? = null
)

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun AgentStatusChip(
    isOnline: Boolean,
    onlineText: String = "Online",
    offlineText: String = "Offline",
    onClick: () -> Unit = {},
    modifier: Modifier = Modifier
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .backgroundColor(Colors.Blue.copy(alpha = 20))
            .padding(topBottom = 5.px, leftRight = 10.px)
            .borderRadius(5.px)
            .onClick { onClick() }
            .then(modifier)
    ) {
        Box(
            modifier = Modifier
                .size(10.px)
                .borderRadius(100.percent)
                .backgroundColor(if (isOnline) Colors.Green else Colors.Red)
        )
        Gap(5.px)
        SpanText(if (isOnline) onlineText else offlineText, modifier = Modifier.fontSize(10.pt))
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest

import com.jamshedalamqaderi.socialdroid.webapp.data.loadJwtToken
import com.jamshedalamqaderi.socialdroid.webapp.data.serverAddress
import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.request.bearerAuth
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

object RestClientFactory {
    private val baseUrl = "http://${serverAddress()}/api/"

    private val httpClient = HttpClient {
        install(ContentNegotiation) {
            json(Json { ignoreUnknownKeys = true })
        }
        defaultRequest {
            val authToken = loadJwtToken()
            if (authToken.isNotEmpty()) {
                bearerAuth(authToken)
            }
            url(baseUrl)
        }
    }

    val authApi = AuthApi(httpClient)

    val agentUserApi = DeviceUserApi(httpClient)

    val taskApi = TaskApi(httpClient)

    val taskDataApi = TaskDataApi(httpClient)

    val instagramApi = InstagramApi(httpClient)

    val deviceAppApi = DeviceAppApi(httpClient)

    val imageApi = ImageApi(httpClient, baseUrl)

    val agentEventApi = AgentEventApi(httpClient)
}

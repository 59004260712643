package com.jamshedalamqaderi.socialdroid.webapp.components.layouts

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.LoginService
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaPowerOff
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun DashboardBodyLayout(title: String, content: @Composable () -> Unit) {
    val pageContext = rememberPageContext()
    Column(
        modifier = Modifier
            .backgroundColor(Colors.Blue.copy(alpha = 10))
            .flex(1)
            .fillMaxHeight()
            .padding(10.px)
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier
                .fillMaxWidth()
        ) {
            SpanText(
                title,
                modifier = Modifier
                    .fontSize(24.pt)
                    .fontWeight(FontWeight.Bold)
            )
            FaPowerOff(
                size = IconSize.X2,
                modifier = Modifier.onClick {
                    GlobalScope.launch {
                        LoginService.instance.logout()
                        pageContext.router.navigateTo("/login")
                    }
                }
            )
        }
        Gap(20.px)
        content()
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.web.events.SyntheticMouseEvent
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceWebsocketEvent
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.ws.WebSocketClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.DisplaySize
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.Gesture
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.RTCPayload
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.Utils.fromJson
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.Utils.toJson
import com.shepeliev.webrtckmp.IceCandidate
import com.shepeliev.webrtckmp.IceServer
import com.shepeliev.webrtckmp.MediaStreamTrackKind
import com.shepeliev.webrtckmp.OfferAnswerOptions
import com.shepeliev.webrtckmp.PeerConnection
import com.shepeliev.webrtckmp.PeerConnectionState
import com.shepeliev.webrtckmp.RtcConfiguration
import com.shepeliev.webrtckmp.SessionDescription
import com.shepeliev.webrtckmp.SessionDescriptionType
import com.shepeliev.webrtckmp.onConnectionStateChange
import com.shepeliev.webrtckmp.onIceCandidate
import com.shepeliev.webrtckmp.onIceConnectionStateChange
import com.shepeliev.webrtckmp.onIceGatheringState
import com.shepeliev.webrtckmp.onSignalingStateChange
import com.shepeliev.webrtckmp.onTrack
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseMove
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.pointerEvents
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaBackward
import com.varabyte.kobweb.silk.components.icons.fa.FaHouse
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.coroutines.plus
import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.HTMLVideoElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent

private val DefaultRtcConfig = RtcConfiguration(
    iceServers = listOf(
        IceServer(
            listOf(
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
                "stun:stun.relay.metered.ca:80"
            )
        ),
        IceServer(
            listOf(
                "turn:a.relay.metered.ca:80",
                "turn:a.relay.metered.ca:80?transport=tcp",
                "turn:a.relay.metered.ca:443",
                "turn:a.relay.metered.ca:443?transport=tcp"
            ),
            "99e43fd57c6f98fc4ec21c0e",
            "cdBAdGtBvbblG1SH"
        )
    )
)

private val DefaultOfferAnswerOptions = OfferAnswerOptions(
    offerToReceiveVideo = true
)

@Composable
fun ScreenMirrorDialog(deviceId: String, onClose: () -> Unit = {}) {
    val coroutineScope = rememberCoroutineScope()
    val sessionsJob = remember { SupervisorJob() }
    val videoRef = remember { mutableStateOf<HTMLVideoElement?>(null) }
    val status = remember { mutableStateOf<String?>("Checking...") }
    val displaySize = remember { mutableStateOf(DisplaySize(1, 1)) }
    val inputText = remember { mutableStateOf("") }
    DisposableEffect(Unit) {
        val peerConnection = PeerConnection(DefaultRtcConfig)
        var keyDowned = false
        val keyDownEventListener = { event: Event ->
            if (event is KeyboardEvent) {
                if (event.ctrlKey) {
                    keyDowned = true
                }
                if (event.key == "v" && keyDowned) {
                    coroutineScope.launch {
                        kotlin.runCatching {
                            val clipboardData = window.navigator.clipboard.readText().await()
                            sendGestureEvent(deviceId, Gesture.InputText(clipboardData))
                        }
                    }
                }
            }
        }
        val keyUpEventListener = { event: Event ->
            if (event is KeyboardEvent) {
                if (event.ctrlKey) {
                    keyDowned = false
                }
            }
        }
        coroutineScope.launch {
            registerListeners(coroutineScope + sessionsJob, peerConnection, videoRef, status)
            peerConnection.onIceCandidate
                .onEach { println("New local ice candidate: $it") }
                .onEach { iceCandidate ->
                    WebSocketClientFactory.send(
                        DeviceWebsocketEvent(
                            "screen_mirror_ice_candidate",
                            RTCPayload(deviceId, iceCandidate.toString()).toJson()
                        )
                    )
                }
                .launchIn(coroutineScope)

            val offerSdp = peerConnection.createOffer(DefaultOfferAnswerOptions).also {
                peerConnection.setLocalDescription(it)
            }

            WebSocketClientFactory.listen("screen_capturer_ws_listener") { eventData ->
                val rtcPayload = eventData.data?.fromJson<RTCPayload>()
                if (rtcPayload?.payload == null) return@listen

                when (eventData.eventType) {
                    "mirror_answer_to_web" -> {
                        peerConnection.setRemoteDescription(
                            SessionDescription(
                                SessionDescriptionType.Answer,
                                rtcPayload.payload
                            )
                        )
                    }

                    "screen_mirror_ice_candidate" -> {
                        kotlin.runCatching {
                            val iceCandidate =
                                rtcPayload.payload.fromJson<HashMap<String, String>>()
                            val sdpMid = iceCandidate?.get("sdpMid")
                            val sdpMLineIndex = iceCandidate?.get("sdpMLineIndex")?.toInt()
                            val candidate = iceCandidate?.get("candidate")
                            if (sdpMid != null && sdpMLineIndex != null && candidate != null) {
                                val newIceCandidate = IceCandidate(
                                    sdpMid,
                                    sdpMLineIndex,
                                    candidate
                                )
                                println("Received Ice Candidate: $newIceCandidate")
                                peerConnection.addIceCandidate(newIceCandidate)
                            }
                        }.onFailure {
                            println("Failed to add received ice candidate: ${rtcPayload.payload}")
                        }
                    }

                    "device_screen_size" -> {
                        rtcPayload.payload.fromJson<DisplaySize>()?.let { data ->
                            displaySize.value = data
                            videoRef.value?.width = data.relativeWidth
                        }
                    }
                }
            }

            WebSocketClientFactory.send(
                DeviceWebsocketEvent(
                    "mirror_offer_to_device",
                    Json.encodeToString(RTCPayload(deviceId, offerSdp.sdp))
                )
            )
        }
        document.addEventListener("keydown", keyDownEventListener)
        document.addEventListener("keyup", keyUpEventListener)
        onDispose {
            coroutineScope.launch {
                println("Sending Screen Mirroring event")
                WebSocketClientFactory
                    .send(DeviceWebsocketEvent("stop_screen_mirroring", deviceId))
            }
            WebSocketClientFactory.remove("screen_capturer_ws_listener")
            peerConnection.close()
            document.removeEventListener("keydown", keyDownEventListener)
            document.removeEventListener("keyup", keyUpEventListener)
        }
    }

    Dialog(
        onClose = {
            coroutineScope.launch {
                println("Sending Screen Mirroring event")
                WebSocketClientFactory
                    .send(DeviceWebsocketEvent("stop_screen_mirroring", deviceId))
            }
            onClose()
        },
        onSubmit = null,
        modifier = Modifier
            .width(720.px)
    ) {
        Column {
            SpanText(
                "Screen Mirroring",
                modifier = Modifier
                    .fontSize(16.pt)
                    .fontWeight(FontWeight.Bold)
            )
            Gap(20.px)
            Row(modifier = Modifier.fillMaxSize()) {
                Column {
                    Box(
                        modifier = Modifier
                            .width(displaySize.value.relativeWidth.px)
                            .height(600.px)
                            .color(Colors.Red)
                            .align(Alignment.CenterHorizontally)
                            .onGesture(
                                videoRef.value,
                                displaySize.value,
                                onClick = {
                                    coroutineScope.launch {
                                        sendGestureEvent(deviceId, it)
                                    }
                                },
                                onLongClick = {
                                    coroutineScope.launch {
                                        sendGestureEvent(deviceId, it)
                                    }
                                },
                                onSwipe = {
                                    coroutineScope.launch {
                                        sendGestureEvent(deviceId, it)
                                    }
                                }
                            ),
                        contentAlignment = Alignment.Center
                    ) {
                        Video(
                            attrs = Modifier
                                .fillMaxSize()
                                .pointerEvents(PointerEvents.None)
                                .toAttrs()
                        ) {
                            DisposableEffect(Unit) {
                                videoRef.value = scopeElement
                                scopeElement.autoplay = true
                                scopeElement.playsInline = true
                                scopeElement.height = 600
                                onDispose {
                                    scopeElement.srcObject = null
                                    videoRef.value = null
                                }
                            }
                        }
                        if (status.value != null) {
                            SpanText(
                                status.value ?: "",
                                modifier = Modifier.fillMaxSize().textAlign(TextAlign.Center)
                            )
                        }
                    }
                    if (status.value == null) {
                        Gap(20.px)
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalArrangement = Arrangement.Center,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            FaHouse(
                                size = IconSize.X1,
                                modifier = Modifier.cursor(Cursor.Pointer).onClick {
                                    coroutineScope.launch {
                                        sendGestureEvent(deviceId, Gesture.NavigationKey("home"))
                                    }
                                }
                            )
                            Gap(20.px)
                            FaBackward(
                                size = IconSize.X1,
                                modifier = Modifier.cursor(Cursor.Pointer).onClick {
                                    coroutineScope.launch {
                                        sendGestureEvent(deviceId, Gesture.NavigationKey("back"))
                                    }
                                }
                            )
                        }
                    }
                }
                if (status.value == null) {
                    Gap(30.px)
                    Column {
                        SpanText(
                            "1) Press and hold left mouse button for gestures",
                            modifier = Modifier.fontSize(12.pt).fontFamily("monospace")
                        )
                        Gap(5.px)
                        SpanText(
                            "2) Press left mouse button for click",
                            modifier = Modifier.fontSize(12.pt).fontFamily("monospace")
                        )
                        Gap(5.px)
                        SpanText(
                            "3) Hold left mouse button for long click",
                            modifier = Modifier.fontSize(12.pt).fontFamily("monospace")
                        )
                        Gap(20.px)
                        TextField(
                            inputText.value,
                            "Input",
                            "Enter Input Text",
                            onValueChange = {
                                inputText.value = it
                            },
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(60.px)
                        )
                        Gap(10.px)
                        TextButton(
                            "Send",
                            backgroundColor = Colors.Blue.copy(alpha = 50),
                            hoverColor = Colors.Blue.copy(alpha = 30),
                            modifier = Modifier
                                .width(100.px)
                                .height(30.px)
                                .onClick {
                                    coroutineScope.launch {
                                        sendGestureEvent(
                                            deviceId,
                                            Gesture.InputText(inputText.value)
                                        )
                                        inputText.value = ""
                                    }
                                }
                        )
                    }
                }
            }
        }
    }
}

private fun registerListeners(
    scope: CoroutineScope,
    peerConnection: PeerConnection,
    videoRef: MutableState<HTMLVideoElement?>,
    status: MutableState<String?>
) {
    peerConnection.onIceGatheringState
        .onEach { println("ICE gathering state changed: $it") }
        .launchIn(scope)

    peerConnection.onConnectionStateChange
        .onEach { println("Connection state changed: $it") }
        .onEach {
            if (it == PeerConnectionState.Connected) {
                status.value = null
            } else {
                status.value = it.name
            }
        }
        .launchIn(scope)

    peerConnection.onSignalingStateChange
        .onEach { println("Signaling state changed: $it") }
        .launchIn(scope)

    peerConnection.onIceConnectionStateChange
        .onEach { println("ICE connection state changed: $it") }
        .launchIn(scope)

    peerConnection.onTrack
        .onEach { console.log("Received Track: ${it.track?.id} | ${it.track?.kind}") }
        .filter { it.track?.kind == MediaStreamTrackKind.Video }
        .onEach { trackEvent ->
            videoRef.value?.srcObject = trackEvent.streams.firstOrNull()?.js
        }
        .launchIn(scope)
}

fun Modifier.onGesture(
    videoRef: HTMLVideoElement?,
    displaySize: DisplaySize,
    onClick: (Gesture.Tap) -> Unit,
    onLongClick: (Gesture.LongTap) -> Unit,
    onSwipe: (Gesture.Swipe) -> Unit
): Modifier {
    val clickGestureHandler = ClickGestureHandler(videoRef, displaySize, onClick, onLongClick)
    val swipeGestureHandler = SwipeGestureHandler(videoRef, displaySize, onSwipe)
    return onMouseDown {
        clickGestureHandler.onMouseDown(it)
        swipeGestureHandler.onMouseDown(it)
    }.onMouseUp {
        clickGestureHandler.onMouseUp(it)
        swipeGestureHandler.onMouseUp(it)
    }.onMouseMove {
        clickGestureHandler.cancel()
        swipeGestureHandler.onMouseMove(it)
    }
}

fun calculateDeviceCoordinate(
    videoRef: HTMLVideoElement?,
    displaySize: DisplaySize,
    event: SyntheticMouseEvent
): DisplaySize {
    val elementRect = videoRef?.getBoundingClientRect()
    val absoluteX = event.clientX
    val absoluteY = event.clientY
    val relativeX = absoluteX - (elementRect?.left ?: 0).toInt()
    val relativeY = absoluteY - (elementRect?.top ?: 0).toInt()
    val deviceX = (displaySize.width * relativeX) / (videoRef?.width ?: 1)
    val deviceY = (displaySize.height * relativeY) / (videoRef?.height ?: 1)
    return DisplaySize(deviceX, deviceY)
}

suspend fun sendGestureEvent(deviceId: String, gesture: Gesture) {
    WebSocketClientFactory.send(
        DeviceWebsocketEvent(
            "screen_mirror_gesture",
            Json.encodeToString(
                RTCPayload(
                    deviceId,
                    Json.encodeToString(gesture)
                )
            )
        )
    )
}

fun detectSwipeGesture(lastSwipeTimestamp: Long, block: () -> Unit) {
    val currentTimestamp = Clock.System.now().toEpochMilliseconds()
    if ((currentTimestamp - lastSwipeTimestamp) > 400 && lastSwipeTimestamp != 0L) {
        block()
    }
}

val DisplaySize.relativeWidth: Int
    get() = ((width.toFloat() / height.toFloat()) * 600).toInt()

class ClickGestureHandler(
    private val videoRef: HTMLVideoElement?,
    private val displaySize: DisplaySize,
    private val onClick: (Gesture.Tap) -> Unit,
    private val onLongClick: (Gesture.LongTap) -> Unit
) {
    private var timeoutCallback: Int? = null
    private var cancelled = false
    fun onMouseDown(event: SyntheticMouseEvent) {
        if (event.button.toInt() != 0) return
        cancelled = false
        timeoutCallback = window.setTimeout({
            val longTapPos = calculateDeviceCoordinate(videoRef, displaySize, event)
            println("Long Pressing on: ${longTapPos.width} | ${longTapPos.height}")
            onLongClick(Gesture.LongTap(longTapPos.width, longTapPos.height))
            cancel()
        }, 500)
    }

    fun onMouseUp(event: SyntheticMouseEvent) {
        if (event.button.toInt() != 0) return
        if (cancelled) return
        timeoutCallback?.let { it1 -> window.clearTimeout(it1) }
        val tapPos = calculateDeviceCoordinate(videoRef, displaySize, event)
        println("Clicking on: ${tapPos.width} | ${tapPos.height}")
        onClick(Gesture.Tap(tapPos.width, tapPos.height))
    }

    fun cancel() {
        cancelled = true
        timeoutCallback?.let(window::clearTimeout)
    }
}

class SwipeGestureHandler(
    private val videoRef: HTMLVideoElement?,
    private val displaySize: DisplaySize,
    private val onSwipe: (Gesture.Swipe) -> Unit
) {
    private var isMouseDowned = false
    private var dragStartTimestamp = 0L
    private var startedPosition: DisplaySize = DisplaySize(1, 1)
    fun onMouseDown(event: SyntheticMouseEvent) {
        if (event.button.toInt() != 0) return
        isMouseDowned = true
        dragStartTimestamp = Clock.System.now().toEpochMilliseconds()
        startedPosition = calculateDeviceCoordinate(videoRef, displaySize, event)
    }

    fun onMouseMove(event: SyntheticMouseEvent) {
        if (event.button.toInt() != 0) return
        if (!isMouseDowned) return
        detectSwipeGesture(dragStartTimestamp) {
            val currentPosition = calculateDeviceCoordinate(videoRef, displaySize, event)
            onSwipe(
                Gesture.Swipe(
                    startedPosition.width.toFloat(),
                    startedPosition.height.toFloat(),
                    currentPosition.width.toFloat(),
                    currentPosition.height.toFloat(),
                    400
                )
            )
            startedPosition = calculateDeviceCoordinate(videoRef, displaySize, event)
            dragStartTimestamp = Clock.System.now().toEpochMilliseconds()
        }
    }

    fun onMouseUp(event: SyntheticMouseEvent) {
        if (event.button.toInt() != 0) return
        isMouseDowned = false
        val duration = Clock.System.now().toEpochMilliseconds() - dragStartTimestamp
        val currentPosition = calculateDeviceCoordinate(videoRef, displaySize, event)
        onSwipe(
            Gesture.Swipe(
                startedPosition.width.toFloat(),
                startedPosition.height.toFloat(),
                currentPosition.width.toFloat(),
                currentPosition.height.toFloat(),
                duration
            )
        )
    }
}

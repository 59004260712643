package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.data.models.IntPair
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.datetime.LocalTime
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.CheckboxInput

@Composable
fun SettingOption(name: String, from: Int, to: Int, onChange: (IntPair) -> Unit = { }) {
    val fromValue = remember { mutableStateOf("$from") }
    val toValue = remember { mutableStateOf("$to") }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(60.px)
            .padding(5.px)
            .borderRadius(3.px)
            .then(DaySelectorHoverStyle.toModifier()),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SpanText(name)
        Row {
            LabeledEditField(fromValue.value, "From", "From", onChange = { value ->
                if (value.isEmpty()) {
                    fromValue.value = value
                    return@LabeledEditField
                }
                val number = value.toIntOrNull()
                if (number != null) {
                    fromValue.value = number.toString()
                    onChange(IntPair(fromValue.value.toIntOrNull(), toValue.value.toIntOrNull()))
                }
            })
            Gap(10.px)
            LabeledEditField(toValue.value, "To", "To", onChange = { value ->
                if (value.isEmpty()) {
                    toValue.value = value
                    return@LabeledEditField
                }
                val number = value.toIntOrNull()
                if (number != null) {
                    toValue.value = number.toString()
                    onChange(IntPair(fromValue.value.toIntOrNull(), toValue.value.toIntOrNull()))
                }
            })
        }
    }
}

@Composable
fun SettingOption(
    name: String,
    from: LocalTime,
    to: LocalTime,
    onChange: (IntPair) -> Unit = { }
) {
    val fromValue = remember { mutableStateOf(from) }
    val toValue = remember { mutableStateOf(to) }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(60.px)
            .padding(5.px)
            .borderRadius(3.px)
            .then(DaySelectorHoverStyle.toModifier()),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SpanText(name)
        Row {
            TimeField(fromValue.value) {
                fromValue.value = it
                onChange(
                    IntPair(
                        (fromValue.value.hour * 100) + fromValue.value.minute,
                        (toValue.value.hour * 100) + toValue.value.minute
                    )
                )
            }
            Gap(10.px)
            TimeField(toValue.value) {
                toValue.value = it
                onChange(
                    IntPair(
                        (fromValue.value.hour * 100) + fromValue.value.minute,
                        (toValue.value.hour * 100) + toValue.value.minute
                    )
                )
            }
        }
    }
}

@Composable
fun SettingOption(
    name: String,
    enabled: Boolean,
    onChange: (Boolean) -> Unit = { }
) {
    val isEnabled = remember { mutableStateOf(enabled) }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(60.px)
            .padding(5.px)
            .borderRadius(3.px)
            .then(DaySelectorHoverStyle.toModifier()),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SpanText(name)
        CheckboxInput(isEnabled.value) {
            onInput {
                isEnabled.value = it.value
                onChange(isEnabled.value)
            }
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class DashboardAppModel(
    val appId: Long,
    val appName: String,
    val packageName: String,
    val followers: Int,
    val completedTasks: Int,
    val queuedTasks: Int,
    val failedTasks: Int,
    val isWorking: Boolean,
    val followBackRatio: Double
)

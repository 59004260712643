package com.jamshedalamqaderi.socialdroid.webapp.data.enums

enum class InstagramTool(val toolName: String, val toolType: ToolType) {
    IG_FOLLOW("Follow", ToolType.ACTION),
    IG_UNFOLLOW("Unfollow", ToolType.ACTION),
    IG_MESSAGE("Message", ToolType.ACTION),
    IG_POSTING("Post", ToolType.ACTION),
    IG_USER_FOLLOWERS_SCRAPER("Followers scraper", ToolType.SCRAPER),
    IG_USER_FOLLOWINGS_SCRAPER("Followings scraper", ToolType.SCRAPER),
    IG_USER_CONTACTS_SCRAPER("Contact scraper", ToolType.SCRAPER),
    IG_LOCATION_USERS_SCRAPER("Location scraper", ToolType.SCRAPER),
    IG_HASHTAG_USERS_SCRAPER("Hashtag scraper", ToolType.SCRAPER),
    IG_FOLLOWERS_SCRAPE("Own Followers", ToolType.SCRAPER),
    IG_FOLLOWING_SCRAPE("Own Followings", ToolType.SCRAPER);

    companion object {
        fun valueOrNull(value: String?): InstagramTool? {
            return InstagramTool.values().find { it.name == value }
        }

        val supportedTools = InstagramTool
            .values()
            .filter { it != IG_FOLLOWERS_SCRAPE }
            .filter { it != IG_FOLLOWING_SCRAPE }
            .toList()
    }
}

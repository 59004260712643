package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.Utils.decodeJwt
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class LoginService {

    companion object {
        val instance = LoginService()
    }

    val deviceAuthToken = mutableStateOf<String?>(null)

    fun isLoggedIn(): Boolean {
        val token = window.localStorage.getItem("token")?.decodeJwt() ?: return false
        return token.expireAt > Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
    }

    suspend fun login(username: String, password: String, rememberMe: Boolean): Boolean {
        if (username.isEmpty()) {
            StaticToastMessageService.showErrorToast("Username or Email field is empty")
            return false
        }
        if (password.isEmpty()) {
            StaticToastMessageService.showErrorToast("Password field is empty")
            return false
        }
        return catch {
            val token = RestClientFactory.authApi.login(username, password, rememberMe)
            if (token != null) {
                window.localStorage.setItem("token", token)
                StaticToastMessageService.showSuccessToast("Login success")
                return@catch true
            }
            return@catch false
        } == true
    }

    suspend fun authDevice(username: String, password: String): Boolean {
        if (username.isEmpty()) {
            StaticToastMessageService.showErrorToast("Username or Email field is empty")
            return false
        }
        if (password.isEmpty()) {
            StaticToastMessageService.showErrorToast("Password field is empty")
            return false
        }
        return catch {
            val token = RestClientFactory.authApi.deviceAuth(username, password)
            if (token != null) {
                this.deviceAuthToken.value = token
                StaticToastMessageService.showSuccessToast("Authenticated successfully")
                return@catch true
            }
            return@catch false
        } == true
    }

    fun token(): String? {
        return window.localStorage.getItem("token")
    }

    fun logout() {
        window.localStorage.setItem("token", "")
    }
}

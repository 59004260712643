package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class ResponseResult<T>(
    val statusCode: Int,
    val data: T? = null,
    val isSuccess: Boolean,
    val failure: String? = null
)

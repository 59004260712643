package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
inline fun <reified T> DropdownOption(
    selectedIndexValue: String,
    options: List<T>,
    crossinline index: (T) -> String,
    crossinline title: (T) -> String = { "" },
    crossinline subtitle: (T) -> String = { "" },
    crossinline onOptionSelected: (T) -> Unit = {}
) {
    val expanded = remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .backgroundColor(Colors.White)
            .border(1.px)
            .borderColor(Colors.Black)
            .borderRadius(5.px)
            .width(250.px)
            .height(60.px)
            .position(Position.Relative)
            .cursor(Cursor.Pointer)
    ) {
        val selectedAgentIndex = options.indexOfFirst { index(it) == selectedIndexValue }
        if (selectedAgentIndex >= 0) {
            SelectedDropdownItem(
                title(options[selectedAgentIndex]),
                subtitle(options[selectedAgentIndex]),
                expanded.value,
                modifier = Modifier
                    .padding(10.px)
                    .onClick {
                        expanded.value = !expanded.value
                    }
            )
        }
        if (expanded.value) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(500.px)
                    .position(Position.Absolute)
                    .overflow(Overflow.Auto)
                    .top(60.px)
                    .margin(top = 3.px)
                    .boxShadow(0.px, 2.px, 5.px, 2.px, Colors.Black.copy(alpha = 30))
                    .borderRadius(5.px)
                    .zIndex(1000)
            ) {
                options.filter { index(it) != selectedIndexValue }
                    .forEach { model ->
                        DropdownItem(
                            title(model),
                            subtitle(model),
                            modifier = Modifier
                                .height(60.px)
                                .padding(10.px)
                                .onClick {
                                    onOptionSelected(model)
                                    expanded.value = false
                                }
                        )
                    }
            }
        }
    }
}

@Composable
fun SelectedDropdownItem(
    agentName: String,
    deviceId: String,
    expanded: Boolean = false,
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier.fillMaxSize(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        DropdownItem(agentName, deviceId)
        FaIcon(
            if (expanded) "angle-up" else "angle-down",
            modifier = Modifier.color(Colors.Blue.copy(alpha = 50)),
            size = IconSize.X1,
            style = IconCategory.SOLID
        )
    }
}

@Composable
fun DropdownItem(agentName: String, deviceId: String, modifier: Modifier = Modifier) {
    Column(
        modifier = modifier
            .backgroundColor(Colors.White)
            .fillMaxWidth()
    ) {
        SpanText(agentName)
        Gap(5.px)
        SpanText(deviceId, modifier = Modifier.fontSize(10.pt).color(Colors.Blue.copy(alpha = 60)))
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.Table
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableData
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.TableHeader
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.TaskState
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.ToolType
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DashboardTask
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.CsvDownloadService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DashboardTasksTableService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.DateTimeExt.format
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.columnGap
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaDownload
import com.varabyte.kobweb.silk.components.icons.fa.FaRotate
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramTasksTable(
    context: PageContext,
    agentId: String,
    instagramTool: InstagramTool,
    appPackageName: String? = null
) {
    val dashboardTasksTableService = remember { DashboardTasksTableService() }
    val params = remember {
        mapOf(
            "agentId" to agentId,
            "instagramTool" to instagramTool.name,
            "appPackageName" to appPackageName
        )
    }
    LaunchedEffect(agentId, instagramTool) {
        dashboardTasksTableService.refresh(params)
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth().columnGap(5.px),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            val styledModifier = Modifier
                .borderRadius(20.px)
                .padding(leftRight = 15.px, topBottom = 5.px)
                .fontSize(10.pt)
                .boxShadow(3.px, 3.px, 5.px, 1.px, Colors.Black.copy(alpha = 40))

            Row(modifier = Modifier.columnGap(10.px)) {
                if (dashboardTasksTableService.selectedTasks.isNotEmpty()) {
                    TextButton(
                        "Execute",
                        backgroundColor = Colors.Blue.copy(alpha = 50),
                        hoverColor = Colors.Blue.copy(alpha = 30),
                        modifier = styledModifier
                            .onClick {
                                GlobalScope.launch {
                                    catch {
                                        if (RestClientFactory.agentEventApi.executeTasksByTaskId(
                                                dashboardTasksTableService.selectedTasks
                                            )
                                        ) {
                                            StaticToastMessageService.showSuccessToast("Successfully executed")
                                            dashboardTasksTableService.refresh(params)
                                        } else {
                                            StaticToastMessageService.showErrorToast("Failed")
                                        }
                                    }
                                }
                            }
                    )

                    TextButton(
                        "Delete",
                        backgroundColor = Colors.Blue.copy(alpha = 50),
                        hoverColor = Colors.Blue.copy(alpha = 30),
                        modifier = styledModifier
                            .onClick {
                                GlobalScope.launch {
                                    catch {
                                        if (RestClientFactory.taskApi.deleteTasks(
                                                dashboardTasksTableService.selectedTasks
                                            )
                                        ) {
                                            StaticToastMessageService.showSuccessToast("Successfully deleted")
                                            dashboardTasksTableService.refresh(params)
                                        } else {
                                            StaticToastMessageService.showErrorToast("Failed")
                                        }
                                    }
                                }
                            }
                    )
                } else {
                    if (instagramTool == InstagramTool.IG_USER_CONTACTS_SCRAPER) {
                        TextButton(
                            "Download Contacts",
                            backgroundColor = Colors.Blue.copy(alpha = 50),
                            hoverColor = Colors.Blue.copy(alpha = 30),
                            modifier = styledModifier
                                .onClick {
                                    GlobalScope.launch {
                                        CsvDownloadService
                                            .instance.retrieveContactData(agentId, appPackageName)
                                    }
                                }
                        )
                    }
                }
            }
            TextButton(
                "New Task",
                backgroundColor = Colors.Blue.copy(alpha = 50),
                hoverColor = Colors.Blue.copy(alpha = 30),
                modifier = styledModifier
                    .onClick {
                        context.router.navigateTo("/create-instagram-task/$agentId/${instagramTool.name}")
                    }
            )
        }
        Gap(10.px)
        Table(
            dataList = dashboardTasksTableService.tasks.value,
            emptyText = "No tasks found!",
            headers = headerList(dashboardTasksTableService, instagramTool),
            cells = columnList(dashboardTasksTableService, instagramTool)
        )
        Gap(10.px)
        if (dashboardTasksTableService.pageCount.value > 0) {
            Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
                Pagination(
                    dashboardTasksTableService.currentPage.value,
                    dashboardTasksTableService.pageCount.value,
                    onPageChange = { nextPage ->
                        GlobalScope.launch {
                            dashboardTasksTableService.currentPage.value = nextPage
                            dashboardTasksTableService.refresh(params)
                        }
                    }
                )
            }
        }
    }
}

@Composable
fun headerList(
    dashboardTasksTableService: DashboardTasksTableService,
    instagramTool: InstagramTool
): ArrayList<@Composable () -> Unit> {
    return arrayListOf<@Composable () -> Unit>().apply {
        add(
            TableHeader {
                Checkbox(
                    dashboardTasksTableService.isSelectedAll.value,
                    modifier = Modifier.onClick { dashboardTasksTableService.onSelectAll() }
                )
            }
        )
        add(
            TableHeader {
                SpanText("ID")
            }
        )
        if (!listOf(
                InstagramTool.IG_POSTING,
                InstagramTool.IG_FOLLOWERS_SCRAPE,
                InstagramTool.IG_FOLLOWING_SCRAPE
            ).contains(instagramTool)
        ) {
            add(
                TableHeader {
                    val headerName = when (instagramTool) {
                        InstagramTool.IG_LOCATION_USERS_SCRAPER -> {
                            "Locations"
                        }

                        InstagramTool.IG_HASHTAG_USERS_SCRAPER -> {
                            "#Tags"
                        }

                        else -> "Usernames"
                    }
                    SpanText(headerName)
                }
            )
        }
        if (instagramTool == InstagramTool.IG_MESSAGE || instagramTool == InstagramTool.IG_POSTING) {
            add(
                TableHeader {
                    SpanText("Message")
                }
            )
        }
        if (!listOf(
                InstagramTool.IG_FOLLOWERS_SCRAPE,
                InstagramTool.IG_FOLLOWING_SCRAPE,
                InstagramTool.IG_POSTING
            ).contains(instagramTool)
        ) {
            add(
                TableHeader {
                    SpanText("Strategy")
                }
            )
        }
        if (instagramTool == InstagramTool.IG_POSTING) {
            add(
                TableHeader {
                    SpanText("Images")
                }
            )
        }
        if (instagramTool == InstagramTool.IG_LOCATION_USERS_SCRAPER || instagramTool == InstagramTool.IG_HASHTAG_USERS_SCRAPER) {
            add(
                TableHeader {
                    SpanText("Scraper Limit")
                }
            )
        }
        add(
            TableHeader {
                SpanText("App")
            }
        )
        if (instagramTool.toolType == ToolType.SCRAPER) {
            val headerName = if (instagramTool == InstagramTool.IG_FOLLOWERS_SCRAPE) {
                "Followers"
            } else if (instagramTool == InstagramTool.IG_FOLLOWING_SCRAPE) {
                "Followings"
            } else {
                "Data Count"
            }
            add(
                TableHeader {
                    SpanText(headerName)
                }
            )
        }
        add(
            TableHeader {
                SpanText("Time")
            }
        )
        add(
            TableHeader {
                SpanText("Status")
            }
        )
        add(
            TableHeader {
                SpanText("Actions")
            }
        )
    }
}

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun columnList(
    dashboardTasksTableService: DashboardTasksTableService,
    instagramTool: InstagramTool
): ArrayList<@Composable (Int, DashboardTask) -> Unit> {
    return arrayListOf<@Composable (Int, DashboardTask) -> Unit>().apply {
        add(
            TableData { _, model ->
                Checkbox(
                    dashboardTasksTableService.selectedTasks.contains(model.id),
                    modifier = Modifier.onClick {
                        dashboardTasksTableService.onTaskSelection(model.id)
                    }
                )
            }
        )
        add(
            TableData { _, model ->
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Start
                ) {
                    SpanText(model.id.toString())
                }
            }
        )
        if (!listOf(
                InstagramTool.IG_POSTING,
                InstagramTool.IG_FOLLOWERS_SCRAPE,
                InstagramTool.IG_FOLLOWING_SCRAPE
            ).contains(instagramTool)
        ) {
            add(
                TableData { _, model ->
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Start
                    ) {
                        SpanText(model.username)
                    }
                }
            )
        }
        if (instagramTool == InstagramTool.IG_MESSAGE || instagramTool == InstagramTool.IG_POSTING) {
            add(
                TableData { _, model ->
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Start
                    ) {
                        SpanText(model.message)
                    }
                }
            )
        }
        if (!listOf(
                InstagramTool.IG_FOLLOWERS_SCRAPE,
                InstagramTool.IG_FOLLOWING_SCRAPE,
                InstagramTool.IG_POSTING
            ).contains(instagramTool)
        ) {
            add(
                TableData { _, model ->
                    SpanText(model.strategy.name)
                }
            )
        }
        if (instagramTool == InstagramTool.IG_POSTING) {
            add(
                TableData { _, model ->
                    Row(modifier = Modifier.columnGap(2.px).rowGap(2.px)) {
                        model.images.forEach {
                            Image(
                                RestClientFactory.imageApi.getImageUrl(it),
                                modifier = Modifier
                                    .size(30.px)
                                    .borderRadius(1000.px)
                                    .boxShadow(
                                        2.px,
                                        2.px,
                                        2.px,
                                        1.px,
                                        Colors.Black.copy(alpha = 40)
                                    )
                            )
                        }
                    }
                }
            )
        }
        if (instagramTool == InstagramTool.IG_LOCATION_USERS_SCRAPER || instagramTool == InstagramTool.IG_HASHTAG_USERS_SCRAPER) {
            add(
                TableData { _, model ->
                    SpanText(model.scraperDataLimit.toString())
                }
            )
        }
        add(
            TableData { _, model ->
                SpanText(model.appName)
            }
        )
        if (instagramTool.toolType == ToolType.SCRAPER) {
            add(
                TableData { _, model ->
                    SpanText(model.dataCount.toString())
                }
            )
        }
        add(
            TableData { _, model ->
                SpanText(model.createdAt.format())
            }
        )
        add(
            TableData { _, model ->
                SpanText(model.status.name)
            }
        )
        add(
            TableData { _, model ->
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center
                ) {
                    if (instagramTool.toolType == ToolType.SCRAPER && instagramTool != InstagramTool.IG_USER_CONTACTS_SCRAPER) {
                        FaDownload(
                            size = IconSize.X1,
                            modifier = Modifier
                                .onClick {
                                    GlobalScope.launch {
                                        CsvDownloadService.instance.retrieveData(
                                            model.id,
                                            instagramTool,
                                            model.username
                                        )
                                    }
                                }
                        )
                    }
                    Gap(10.px)
                    if (model.status != TaskState.Completed) {
                        FaRotate(
                            size = IconSize.X1,
                            modifier = Modifier.onClick {
                                GlobalScope.launch {
                                    catch {
                                        if (RestClientFactory.agentEventApi.executeTasksByTaskId(
                                                listOf(
                                                        model.id
                                                    )
                                            )
                                        ) {
                                            StaticToastMessageService.showSuccessToast("Executed Successfully")
                                        } else {
                                            StaticToastMessageService.showErrorToast("Executing Failed. TaskId: ${model.id}")
                                        }
                                    }
                                }
                            }
                        )
                    }
                }
            }
        )
    }
}

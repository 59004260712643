package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.AgentSelectorModel

@Composable
fun AgentListSelector(
    selectedAgentId: String,
    agents: List<AgentSelectorModel>,
    onAgentSelected: (String) -> Unit = {}
) {
    DropdownOption(
        selectedAgentId,
        agents,
        index = {
            it.agentId
        },
        title = {
            it.agentName
        },
        subtitle = {
            it.agentId
        },
        onOptionSelected = {
            onAgentSelected(it.agentId)
        }
    )
}

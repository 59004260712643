package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.AgentListSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramAppDropdown
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramTasksTable
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramToolSelector
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.data.models.DeviceAppModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.AgentSelectorModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramAppService
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px

@Page("/instagram-tasks/{agentId}/{instagramTool}")
@Composable
fun Tasks() {
    val context = rememberPageContext()
    val appService = remember { InstagramAppService() }
    val agentId = remember { mutableStateOf("") }
    val instagramTool = remember { mutableStateOf<InstagramTool?>(null) }
    val selectedAppPackageName = remember { mutableStateOf("all.apps") }

    LaunchedEffect(context.route) {
        agentId.value = context.route.params["agentId"] ?: ""
        instagramTool.value = InstagramTool.valueOrNull(context.route.params["instagramTool"])
        selectedAppPackageName.value = context.route.params["app"] ?: "all.apps"
        DeviceService.instance.refresh(mapOf())
        appService.refresh(params = mapOf("agentId" to agentId.value))
    }
    if (instagramTool.value == null) {
        SpanText("No ToolType selected")
        return
    }
    MainMenuLayout(SocialdroidMainMenuOptions.InstagramTool) {
        DashboardBodyLayout("Instagram Tasks") {
            Row {
                AgentListSelector(
                    agentId.value,
                    DeviceService.instance.devices.value.map {
                        AgentSelectorModel(it.deviceId!!, it.deviceName!!)
                    },
                    onAgentSelected = {
                        agentId.value = it
                        context.router.navigateTo("/instagram-tasks/${agentId.value}/${instagramTool.value}?app=${selectedAppPackageName.value}")
                        window.location.reload()
                    }
                )
                Gap(10.px)
                InstagramToolSelector(
                    instagramTool.value!!,
                    InstagramTool.supportedTools,
                    onToolSelected = {
                        instagramTool.value = it
                        context.router.navigateTo("/instagram-tasks/${agentId.value}/${instagramTool.value}?app=${selectedAppPackageName.value}")
                        window.location.reload()
                    }
                )
                Gap(10.px)
                InstagramAppDropdown(
                    selectedApp = selectedAppPackageName.value,
                    apps = buildList {
                        add(DeviceAppModel(-1, agentId.value, "All", "all.apps"))
                        addAll(appService.apps)
                    },
                    onAppSelected = { app ->
                        selectedAppPackageName.value = app.packageName
                        context.router.navigateTo("/instagram-tasks/${agentId.value}/${instagramTool.value}?app=${selectedAppPackageName.value}")
                        window.location.reload()
                    }
                )
            }
            Gap(20.px)
            InstagramTasksTable(
                context,
                agentId.value,
                instagramTool.value!!,
                if (selectedAppPackageName.value == "all.apps") null else selectedAppPackageName.value
            )
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.utils

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

object DateTimeExt {
    fun LocalDateTime.format(): String {
        val dateTime = this.toInstant(TimeZone.currentSystemDefault())
            .toLocalDateTime(TimeZone.currentSystemDefault())
        return "${dateTime.hour.zeroPad}:${dateTime.minute.zeroPad}:${dateTime.second.zeroPad} ${dateTime.dayOfMonth.zeroPad}/${dateTime.monthNumber.zeroPad}/${dateTime.year}"
    }

    val Int.zeroPad: String
        get() = if (this < 10) "0$this" else "$this"

    fun Int.toLocalTime(): LocalTime {
        val hour = this / 100
        val minute = this - (hour * 100)
        return LocalTime(hour, minute)
    }
}

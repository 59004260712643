package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.InstagramToolsTableHoverStyle
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@Composable
fun ShowUsernamesDialog(
    title: String,
    usernames: List<String>,
    onDelete: (Int) -> Unit = {},
    deleteAll: () -> Unit = {},
    hideDialog: () -> Unit = {}
) {
    Dialog(
        onClose = {
            deleteAll()
            hideDialog()
        },
        onSubmit = {
            hideDialog()
        },
        closeButtonText = "Clear All",
        submitButtonText = "OK"
    ) {
        SpanText(title, modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold))
        Gap(10.px)
        Column(modifier = Modifier.fillMaxWidth()) {
            usernames.forEachIndexed { index, username ->
                UsernameListItem(index, username, onDelete)
            }
        }
        Gap(30.px)
    }
}

@Composable
fun UsernameListItem(index: Int, username: String, onDelete: (Int) -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth().height(40.px)
            .then(InstagramToolsTableHoverStyle.toModifier()),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row {
            SpanText("${index + 1}.", modifier = Modifier.width(30.px))
            SpanText(username)
        }
        FaXmark(
            modifier = Modifier
                .color(Colors.Red)
                .cursor(Cursor.Pointer)
                .onClick {
                    onDelete(index)
                }
        )
    }
}

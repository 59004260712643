package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

val MainMenuUnSelectedHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Blue.copy(alpha = 20))
    }

    hover {
        Modifier.backgroundColor(Colors.Blue.copy(alpha = 30))
    }
}

val MainMenuSelectedHoverStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Color.argb(0x0b00ff42))
    }
}

@Composable
fun MainMenuOption(
    context: PageContext = rememberPageContext(),
    iconName: String,
    optionName: String,
    route: String,
    selected: Boolean = false
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .padding(10.px)
            .borderRadius(5.px)
            .cursor(Cursor.Pointer)
            .onClick {
                context.router.navigateTo(route)
            }
            .then((if (selected) MainMenuSelectedHoverStyle else MainMenuUnSelectedHoverStyle).toModifier())
    ) {
        FaIcon(
            iconName,
            style = IconCategory.SOLID,
            size = IconSize.X1,
            modifier = Modifier
                .padding(10.px)
                .backgroundColor(Colors.Blue.copy(alpha = 30))
                .borderRadius(5.px)
                .color(Colors.Blue.copy(alpha = 90))
        )
        Gap(10.px)
        SpanText(optionName)
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.domain.utils

import com.jamshedalamqaderi.socialdroid.webapp.BuildKonfig
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.UserToken
import io.github.g0dkar.qrcode.QRCode
import kotlinx.browser.window
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.int
import kotlinx.serialization.json.jsonPrimitive
import kotlinx.serialization.json.long

object Utils {
    val json = Json { ignoreUnknownKeys = true }
    fun generateServerQrCode(token: String): String {
        val ip = window.location.hostname
        val portValue = if (BuildKonfig.environment == "DEBUG") "8080" else window.location.port
        val port = if (portValue.isEmpty()) 80 else portValue.toInt()
        val jsonObject = JsonObject(
            mapOf(
                "ip" to JsonPrimitive(ip),
                "port" to JsonPrimitive(port),
                "token" to JsonPrimitive(token)
            )
        )
        return QRCode(Json.encodeToString(jsonObject))
            .render()
            .toDataURL()
    }

    fun String.decodeJwt(): UserToken? {
        if (this.isEmpty()) return null
        val parts = this.split(".")
        val base64Payload = parts[1]
        val payload = Json.decodeFromString<JsonObject>(window.atob(base64Payload))
        val roles =
            Json.decodeFromString<List<String>>(payload["roles"]?.jsonPrimitive?.content ?: "")
        val expireAt = Instant.fromEpochSeconds(payload["exp"]?.jsonPrimitive?.long ?: 0)
        return kotlin.runCatching {
            UserToken(
                payload["appLimit"]?.jsonPrimitive?.int!!,
                payload["subscriptionType"]?.jsonPrimitive.toString(),
                roles,
                payload["iss"]?.jsonPrimitive.toString(),
                expireAt.toLocalDateTime(TimeZone.currentSystemDefault()),
                payload["deviceLimit"]?.jsonPrimitive?.int!!,
                payload["username"]?.jsonPrimitive.toString()
            )
        }.getOrNull()
    }

    inline fun <reified T> String.fromJson(): T? {
        return try {
            json.decodeFromString<T>(this)
        } catch (e: Exception) {
            return null
        }
    }

    inline fun <reified T> T.toJson(): String? {
        return try {
            json.encodeToString(this)
        } catch (_: Exception) {
            null
        }
    }
}

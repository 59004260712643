package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.AgentListSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.AgentStatus
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramToolsTable
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.WorkingDays
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.AgentSelectorModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.px

@Page("/instagram-tools/{agentId}")
@Composable
fun InstagramTools() {
    val context = rememberPageContext()

    val selectedAgentId = remember { mutableStateOf("") }

    LaunchedEffect(context.route.params) {
        DeviceService.instance.refresh(emptyMap())
        selectedAgentId.value = context.route.params["agentId"] ?: ""
    }

    MainMenuLayout(SocialdroidMainMenuOptions.InstagramTool) {
        DashboardBodyLayout("Instagram Tools") {
            Row(verticalAlignment = Alignment.CenterVertically) {
                AgentListSelector(
                    selectedAgentId.value,
                    DeviceService.instance.devices.value.map {
                        AgentSelectorModel(it.deviceId!!, it.deviceName!!)
                    },
                    onAgentSelected = { newSelectedAgentId ->
                        selectedAgentId.value = newSelectedAgentId
                        context.router.navigateTo("/instagram-tools/${selectedAgentId.value}")
                    }
                )
                Gap(15.px)
                AgentStatus(selectedAgentId.value)
                Gap(15.px)
                WorkingDays(selectedAgentId.value)
            }
            Gap(20.px)
            InstagramToolsTable(context, selectedAgentId.value)
        }
    }
}

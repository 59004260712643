package com.jamshedalamqaderi.socialdroid.webapp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TextArea

val TextFieldStyle by ComponentStyle {
    base {
        Modifier.border(1.px, LineStyle.Solid, Colors.Grey)
    }
    focus {
        Modifier.border(2.px, LineStyle.Solid, Colors.Blue.copy(alpha = 50))
    }
}

@Composable
fun TextField(
    inputValue: String,
    title: String,
    placeholder: String,
    onValueChange: (String) -> Unit = {},
    option: @Composable () -> Unit = {},
    modifier: Modifier = Modifier
) {
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth(),
//            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            SpanText(title, modifier = Modifier.fontSize(16.pt).fontWeight(FontWeight.Bold))
            Gap(20.px)
            option()
        }
        Gap(10.px)
        TextArea(
            inputValue,
            attrs = TextFieldStyle.toModifier()
                .outline(0.px, LineStyle.None)
                .fillMaxWidth()
                .minHeight(80.px)
                .padding(10.px)
                .borderRadius(5.px)
                .then(modifier)
                .toAttrs {
                    placeholder(placeholder)
                    onInput {
                        onValueChange(it.value)
                    }
                }
        )
    }
}

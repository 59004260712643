package com.jamshedalamqaderi.socialdroid.webapp

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.StaticToastMessage
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.VerifyAuth
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.ws.WebSocketClientFactory
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerBaseStyle
import kotlinx.browser.window
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw

@InitSilk
fun updateTheme(ctx: InitSilkContext) {
    ctx.stylesheet.registerBaseStyle("body") {
        Modifier.fontFamily("Roboto")
    }
}

@App
@Composable
fun MyApp(content: @Composable () -> Unit) {
    LaunchedEffect(Unit) {
        window.addEventListener("contextmenu", { event ->
            event.preventDefault()
        })
        try {
            WebSocketClientFactory.connect()
        } catch (e: Exception) {
            println("Exception on connecting websocket: ${e.message}")
        }
    }
    SilkApp {
        Surface(
            SmoothColorStyle
                .toModifier()
                .minWidth(100.vw)
                .minHeight(100.vh)
        ) {
            VerifyAuth {
                renderWithDeferred {
                    StaticToastMessage()
                }
                content()
            }
        }
    }
}

package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramStrategy
import kotlinx.serialization.Serializable

@Serializable
data class InstagramToolTaskDto(
    val username: String,
    val packageName: String,
    val strategyType: InstagramStrategy,
    val message: String? = null,
    val images: List<String>? = null,
    val scraperDataLimit: Int? = null
)

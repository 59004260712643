package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import androidx.compose.runtime.mutableStateOf
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.StaticToastMessage

class StaticToastMessageServiceImpl private constructor() : StaticToastMessageService() {
    companion object {
        val instance = StaticToastMessageServiceImpl()
    }

    val toastState =
        mutableStateOf<StaticToastMessage>(StaticToastMessage.None)

    override fun showToast(message: StaticToastMessage) {
        toastState.value = message
    }
}

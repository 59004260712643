import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.jamshedalamqaderi.socialdroid.webapp.pages.DashboardHome() }
        ctx.router.register("/buy-more-servers") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.BuyMoreServers() }
        ctx.router.register("/connect-agent") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.ConnectAgent() }
        ctx.router.register("/contact-support") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.ContactSupport() }
        ctx.router.register("/create-instagram-task/{agentId}/{instagramTool}") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.InstagramCreateTask() }
        ctx.router.register("/download-agent") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.DownloadAgent() }
        ctx.router.register("/instagram-tasks/{agentId}/{instagramTool}") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.Tasks() }
        ctx.router.register("/instagram-tools/{agentId}") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.InstagramTools() }
        ctx.router.register("/login") { com.jamshedalamqaderi.socialdroid.webapp.pages.Login() }
        ctx.router.register("/mass-emailer-software") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.MassEmailerSoftware() }
        ctx.router.register("/tutorials") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.Tutorials() }
        ctx.router.register("/update-logs") {
                com.jamshedalamqaderi.socialdroid.webapp.pages.UpdateLogs() }

    }
    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.layouts.hideScrollbarStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TextFieldStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.MainMenuUnSelectedHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.MainMenuSelectedHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ArrowHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.table.InstagramToolsTableHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ButtonHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.DaySelectorHoverStyle)
        ctx.theme.registerComponentStyle(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.ImagePreviewHoverStyle)
        ctx.stylesheet.registerKeyframes(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Rotate360)
        ctx.stylesheet.registerKeyframes(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.SwitchOnAnimation)
        ctx.stylesheet.registerKeyframes(com.jamshedalamqaderi.socialdroid.webapp.components.widgets.SwitchOffAnimation)
        com.jamshedalamqaderi.socialdroid.webapp.updateTheme(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin))

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "Socialdroid")
        ) { com.jamshedalamqaderi.socialdroid.webapp.MyApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}

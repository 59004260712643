package com.jamshedalamqaderi.socialdroid.webapp.components.layouts.instagramtasks

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramAppsSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TaskSubmitButton
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.InstagramOwnFollowingsTaskSubmitService
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun InstagramOwnFollowingsScraperCreateTaskLayout(agentId: String) {
    LaunchedEffect(agentId) {
        InstagramOwnFollowingsTaskSubmitService.instance.agentId = agentId
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        InstagramAppsSelector(agentId, onSelected = {
            InstagramOwnFollowingsTaskSubmitService.instance.appPackageNames = it
        })
        Gap(20.px)
        TaskSubmitButton(onClick = {
            GlobalScope.launch {
                InstagramOwnFollowingsTaskSubmitService.instance.onSubmit()
            }
        })
    }
}

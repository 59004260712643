package com.jamshedalamqaderi.socialdroid.webapp.data.models

import kotlinx.serialization.Serializable

@Serializable
data class AuthLoginModel(
    val username: String,
    val password: String,
    val rememberMe: Boolean = false
)

package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.InstagramTaskCreateLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.AgentListSelector
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.InstagramToolSelector
import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramTool
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.jamshedalamqaderi.socialdroid.webapp.domain.models.AgentSelectorModel
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.DeviceService
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Page("/create-instagram-task/{agentId}/{instagramTool}")
@Composable
fun InstagramCreateTask() {
    val context = rememberPageContext()

    val agentId = remember { mutableStateOf("") }
    val instagramTool = remember { mutableStateOf<InstagramTool?>(null) }

    LaunchedEffect(context) {
        agentId.value = context.route.params["agentId"] ?: ""
        instagramTool.value = InstagramTool.valueOrNull(context.route.params["instagramTool"])
        DeviceService.instance.refresh(emptyMap())
    }
    if (instagramTool.value == null) {
        SpanText("No InstagramTool found")
        return
    }
    MainMenuLayout(SocialdroidMainMenuOptions.InstagramTool) {
        DashboardBodyLayout("Create Instagram Task") {
            Row {
                AgentListSelector(
                    agentId.value,
                    DeviceService.instance.devices.value.map {
                        AgentSelectorModel(it.deviceId!!, it.deviceName!!)
                    },
                    onAgentSelected = {
                        agentId.value = it
                        context.router.navigateTo("/create-instagram-task/${agentId.value}/${instagramTool.value}")
                    }
                )
                Gap(10.px)
                InstagramToolSelector(
                    instagramTool.value!!,
                    InstagramTool.supportedTools,
                    onToolSelected = {
                        instagramTool.value = it
                        context.router.navigateTo("/create-instagram-task/${agentId.value}/${instagramTool.value}")
                    }
                )
            }
            Gap(20.px)
            InstagramTaskCreateLayout(agentId.value, instagramTool.value!!)
        }
    }
}

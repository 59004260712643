package com.jamshedalamqaderi.socialdroid.webapp.data.models

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramStrategy
import kotlinx.serialization.Serializable

@Serializable
data class InstagramUnfollowAllFollowingsToolDto(
    val strategyType: InstagramStrategy,
    val packageNames: List<String>
)

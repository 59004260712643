package com.jamshedalamqaderi.socialdroid.webapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.DashboardBodyLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.layouts.MainMenuLayout
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Dialog
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.Gap
import com.jamshedalamqaderi.socialdroid.webapp.components.widgets.TextButton
import com.jamshedalamqaderi.socialdroid.webapp.domain.enums.SocialdroidMainMenuOptions
import com.jamshedalamqaderi.socialdroid.webapp.domain.services.LoginService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.Utils.generateServerQrCode
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px

@OptIn(DelicateCoroutinesApi::class)
@Page("/connect-agent")
@Composable
fun ConnectAgent() {
    val showAuthDialog = remember { mutableStateOf(false) }
    MainMenuLayout(SocialdroidMainMenuOptions.ConnectAgent) {
        DashboardBodyLayout("Connect Agent") {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                if (LoginService.instance.deviceAuthToken.value != null) {
                    SpanText(
                        "Scan from 'Socialdroid' app",
                        modifier = Modifier
                            .fontSize(12.pt)
                            .fontWeight(FontWeight.Bold)
                    )
                    Gap(20.px)
                    Image(
                        generateServerQrCode(LoginService.instance.deviceAuthToken.value!!),
                        modifier = Modifier.size(300.px)
                            .boxShadow(5.px, 5.px, 10.px, 2.px, Colors.Black.copy(alpha = 30))
                            .backgroundColor(Colors.White)
                            .padding(20.px)
                            .borderRadius(10.px)
                    )
                } else {
                    SpanText(
                        "You have to authorize to generate qr code",
                        modifier = Modifier.fontSize(12.pt).fontWeight(
                            FontWeight.Bold
                        ).color(Colors.Red)
                    )
                    Gap(10.px)
                    TextButton(
                        "Authorize",
                        backgroundColor = Colors.Blue.copy(alpha = 80),
                        hoverColor = Colors.Blue.copy(alpha = 75),
                        modifier = Modifier
                            .padding(leftRight = 15.px, topBottom = 5.px)
                            .borderRadius(5.px)
                            .onClick {
                                showAuthDialog.value = true
                            }
                    )
                }
            }
        }
    }
    if (showAuthDialog.value) {
        val username = remember { mutableStateOf("") }
        val password = remember { mutableStateOf("") }
        Dialog(
            modifier = Modifier
                .width(350.px)
                .padding(10.px)
                .backgroundColor(Colors.White)
                .boxShadow(3.px, 3.px, 6.px, 2.px, Colors.Black.copy(alpha = 30))
                .borderRadius(5.px),
            onClose = {
                showAuthDialog.value = false
            },
            submitButtonText = "Generate",
            onSubmit = {
                GlobalScope.launch {
                    if (LoginService.instance.authDevice(
                            username.value,
                            password.value
                        )
                    ) {
                        showAuthDialog.value = false
                    }
                }
            }
        ) {
            LoginForm(
                "Login",
                "Authorize",
                null,
                usernameChanged = {
                    username.value = it
                },
                passwordChanged = {
                    password.value = it
                },
                null
            )
            Gap(20.px)
        }
    }
}

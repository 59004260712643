package com.jamshedalamqaderi.socialdroid.webapp.domain.services

import com.jamshedalamqaderi.socialdroid.webapp.data.enums.InstagramStrategy
import com.jamshedalamqaderi.socialdroid.webapp.data.models.InstagramToolTaskDto
import com.jamshedalamqaderi.socialdroid.webapp.data.remote.rest.RestClientFactory
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.StaticToastMessageService
import com.jamshedalamqaderi.socialdroid.webapp.domain.interfaces.TaskSubmitService
import com.jamshedalamqaderi.socialdroid.webapp.domain.utils.ExceptionUtil.catch

open class InstagramFollowTaskSubmitService : TaskSubmitService {
    companion object {
        val instance = InstagramFollowTaskSubmitService()
    }

    var strategy: InstagramStrategy = InstagramStrategy.VISIT
    var appPackageNames = listOf<String>()
    var agentId: String = ""

    override suspend fun onSubmit() {
        if (agentId.isEmpty()) {
            StaticToastMessageService.showErrorToast("AgentId is not selected")
            return
        }
        if (appPackageNames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No apps has been selected")
            return
        }
        if (InstagramUsernameInputService.instance.usernames.isEmpty()) {
            StaticToastMessageService.showErrorToast("No usernames has been given")
            return
        }
        val tasks = arrayListOf<InstagramToolTaskDto>()
        InstagramUsernameInputService.instance.usernames.forEach { username ->
            appPackageNames.forEach { packageName ->
                tasks.add(InstagramToolTaskDto(username, packageName, strategy))
            }
        }
        catch {
            val result = RestClientFactory.instagramApi.createFollowBot(agentId, tasks)
            if (result) {
                StaticToastMessageService.showSuccessToast("Successfully started...")
                InstagramUsernameInputService.instance.usernames.clear()
            } else {
                StaticToastMessageService.showErrorToast("Something went wrong. Failed...")
            }
        }
    }
}
